
.pagenavi {
  font-size: 1.125rem; }
  .pagenavi span,
  .pagenavi a {
    position: relative;
    padding: 5px 5px 4px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    background-color: #dfc08c;
    margin: 0 1px;
    min-width: 26px;
    height: 29px;
    font-size: .875rem; }
    .pagenavi span:hover,
    .pagenavi a:hover {
      text-decoration: none;
      color: #fff; }
  .pagenavi a.nextpostslink {
    margin-right: 0; }
  .pagenavi span.current {
    font-weight: 600;
    background-color: #595959; }

/*# sourceMappingURL=all_articles.css.map */
