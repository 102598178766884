.our__philosophy h1{
    font-size: 4.6875rem;
    color: #9fba5d;
    font-family: "PopulaireLight",Arial,Verdana;
    text-transform: uppercase;
    margin-bottom: 26px;
}

.our__philosophy blockquote{
    font-weight: 300;
}


