.margin_custom {
  margin-top: 2%; }

.title-review {
  text-align: center;
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland",Arial,Verdana;
  width: 100%; }

.same__font {
  font-size: .875rem; }

.another__font {
  font-size: 1.375rem; }

#reviews {
  margin-top: 34px; }
  #reviews p {
    margin-bottom: 10px; }
  #reviews #comments .Reviews-title {
    display: none; }
  #reviews #comments .noreviews {
    line-height: 30px; }
  #reviews #review_form_wrapper .comment-reply-title {
    line-height: 22px; }
  #reviews #review_form_wrapper .comment-form {
    line-height: 30px; }
    #reviews #review_form_wrapper .comment-form .comment-form-rating {
      line-height: 22px; }
      #reviews #review_form_wrapper .comment-form .comment-form-rating .stars {
        display: inline-block;
        margin-left: 8px;
        line-height: 30px; }
        #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a {
          padding: 0 4px; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:hover {
            text-decoration: none; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:focus > i, #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:active > i {
            color: #000; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:before {
            transition: all .3s ease; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a i {
            color: #d6d6d6; }
    #reviews #review_form_wrapper .comment-form .comment-form-comment {
      line-height: 30px; }
      #reviews #review_form_wrapper .comment-form .comment-form-comment textarea {
        resize: none;
        width: 100%;
        height: 166px;
        background-color: transparent;
        border: 1px #000 solid;
        padding: 12px 12px 8px;
        font-size: .9375rem;
        color: #000;
        font-family: "HelveticaNeueLTStd",Arial,Verdana;
        outline: 0;
        display: block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 0;
        -ms-border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        overflow: auto;
        vertical-align: top;
        margin: 0; }
        #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:hover, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:focus, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:active {
          background-color: rgba(0, 0, 0, 0.02);
          border-color: #477824; }
    #reviews #review_form_wrapper .comment-form .input__nombre {
      width: 100%;
      margin: 0 !important; }
      #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author {
        width: 100%;
        margin-bottom: 0; }
        #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input {
          width: 100%;
          background-color: transparent;
          border: 1px #000 solid;
          padding: 3px 10px 3px;
          font-size: 0.873rem;
          color: #000;
          font-family: "HelveticaNeueLTStd",Arial,Verdana;
          outline: 0;
          display: block;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          -o-box-sizing: border-box;
          -ms-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 0;
          -ms-border-radius: 0;
          -moz-border-radius: 0;
          -webkit-border-radius: 0;
          -khtml-border-radius: 0; }
          #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:hover, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:focus, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:active {
            background-color: rgba(0, 0, 0, 0.02);
            border-color: #477824; }
    #reviews #review_form_wrapper .comment-form .pprivacy {
      font-size: .875rem;
      line-height: 22px;
      margin-top: 10px; }
      #reviews #review_form_wrapper .comment-form .pprivacy .privacyBox {
        position: relative;
        margin-right: 10px;
        vertical-align: middle;
        width: 16px;
        height: 16px; }
      #reviews #review_form_wrapper .comment-form .pprivacy a {
        font-weight: 700;
        color: #000; }
    #reviews #review_form_wrapper .comment-form .form-submit {
      margin-top: 28px; }
      #reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro {
        height: 36px;
        text-transform: uppercase;
        background-color: #dfc08c;
        border: 0;
        -webkit-border-radius: 0%;
        -moz-border-radius: 0%;
        border-radius: 0%;
        color: #fff;
        text-decoration: none;
        display: inline-block;
        transition: all .3s ease;
        text-align: center;
        line-height: normal;
        padding: 8px 18px 4px;
        font-family: "HelveticaNeueLTStd",Arial,Verdana;
        border: none; }
        #reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro:hover {
          background-color: #595959;
          color: #fff; }
  #reviews #review_form_wrapper .Legal-form {
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px; }
    #reviews #review_form_wrapper .Legal-form p {
      margin: unset;
      letter-spacing: -1px;
      font-size: 12px; }
    #reviews #review_form_wrapper .Legal-form ul {
      padding: 0; }
      #reviews #review_form_wrapper .Legal-form ul li {
        list-style: none;
        font-size: .75rem;
        line-height: 22px;
      }
        #reviews #review_form_wrapper .Legal-form ul li a {
          color: #000;
          text-decoration: underline; }

/*# sourceMappingURL=ratings.css.map */
