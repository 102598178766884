@font-face {
  font-family: "Freeland";
  src: url("../font/Freeland.woff") format("woff"); }
@font-face {
  font-family: "PopulaireLight";
  src: url("../font/Populaire Light.ttf") format("truetype"); }
@font-face {
  font-family: "Helvetica Bd";
  src: url("../font/HelveticaNeueLTStd-Bd.woff") format("woff"); }
@font-face {
  font-family: "Helvetica Lt";
  src: url("../font/HelveticaNeueLTStd-Lt.woff") format("woff"); }
@font-face {
  font-family: "Helvetica Md";
  src: url("../font/HelveticaNeueLTStd-Md.woff") format("woff"); }
@font-face {
  font-family: "Helvetica Rom";
  src: url("../font/HelveticaNeueLTStd-Roman.woff") format("woff"); }
@font-face {
  font-family: "Helvetica Std";
  src: url("../font/HelveticaNeueLTStd-Roman.woff") format("woff"); }
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');

.freeland {
  font-family: "Freeland", Arial, Verdana;
  text-decoration: none;
  font-size: 3.25rem;
}
.populaireLight{
  font-family: "PopulaireLight",Arial,Verdana !important;
}
.helv_bd{
  font-family: "Helvetica Bd", Arial, Verdana !important;
}
.helv_lt{
  font-family: "Helvetica Lt", Arial, Verdana !important;
}
.helv_md{
  font-family: "Helvetica Md", Arial, Verdana !important;
}
.helv_rom{
  font-family: "Helvetica Rom", Arial, Verdana !important;
}
.helv_std{
  font-family: "Helvetica Std", Arial, Verdana !important;
}
.montserrat{
  font-family: 'Montserrat', sans-serif;
  font-size: 15px !important;
}
.montserrat_semi_bd{
  font-family: 'Montserrat Semi-Bold', sans-serif;
}
.montserrat_bd{
  font-family: 'Montserrat Bold', sans-serif;
}

.montserrat{
  font-family: 'Montserrat', sans-serif;
  font-size: 15px !important;
}
.montserrat_semi_bd{
   font-family: 'Montserrat Semi-Bold', sans-serif;
 }
.montserrat_bd{
  font-family: 'Montserrat Bold', sans-serif;
}

* {
  font-family: "HelveticaNeueLTStd", Arial, Verdana; }
#main_custom .row_custom_small+.row_custom_small {
  margin-top: 20px;
}
a {
  text-decoration: none;
  transition: all .3s ease;
}

.img_custom_full{
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none;
  max-height: 100%;}

#main_custom .btn {
  text-decoration: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 10px 12px 8px;
  width: auto;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  font-size: 13px;
  color: #fff;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;
}

.btn_custom{
  height: 45px;
  line-height: 36px;
  font-size: 1.375rem;
  text-transform: uppercase;
  background-color: #dfc08c;
  border: 1px solid #dfc08c;
  padding: 8px 18px 4px;
  color: #fff;
}

.btn_oro{
  background-color: #dfc08c;
  color: #fff;
}

[class*=col-] {
  float: left;
  width: 100%;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

.row_custom_small {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1100px;
  margin-left: auto !important;
  margin-right: auto !important; }

.Separa-cn {
  margin: 20px 0 40px;
  text-align: center;
}

blockquote{
  max-width: 990px;
  font-size: 1.375rem;
  line-height: 30px;
  margin: 24px auto 14px;
  margin-bottom: 40px;
  quotes: none;
}

header{
  z-index: 999;
}

#menu1 {
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: -11px; }
#menu1 .container {
  padding-right: 45px;
  padding-left: 45px; }
#menu1 .container .navbar-nav .nav-item {
  font-size: .75rem;
  margin-left: 40px; }
#menu1 .container .navbar-nav .nav-item a {
  font-size: .75rem;
  color: #000 !important;
  font: normal normal normal 14px/1 FontAwesome; }

#menu2 {
  background-color: #fff; }
#menu2 .navbar-nav .nav-item {
  padding: 22px 30px 17px; }
#menu2 .navbar-nav .nav-item:hover {
  background-color: #f0f0f0; }
#menu2 .navbar-nav .nav-item .nav-link {
  font-size: .875rem;
  color: #000 !important;
  text-transform: uppercase; }
#menu2 .navbar-nav .nav-item .nav-link:hover {
  color: #9fba5d; }
#menu2 .navbar-nav .nav-item .menu-hd {
  margin-left: 28px; }

#main_custom .carousel-indicators {
  bottom: -60px; }
#main_custom .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #c0bfbf; }
#main_custom .carousel-indicators li.active {
  background-color: #9fba5d; }
#main_custom .section2 .pad,
#main_custom .section3 .pad,
#main_custom .section4 .pad {
  padding: 20px;
  margin-top: 15px;}
#main_custom .section2 .pad h1,
#main_custom .section3 .pad h1,
#main_custom .section4 .pad h1 {
  font-size: 2.125rem;
  font-family: "Freeland",Arial,Verdana;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
}
#main_custom .section2 h2{
  padding: 1% 0;
  font-size: 18px;
}
.nextSec2{
  margin-top: -8px !important;
}
#main_custom .section2 .portfolio-caption,
#main_custom .section3 .portfolio-caption,
#main_custom .section4 .portfolio-caption {
  margin: 1em;
  height: 130px;
  line-height: 26px;
}
#main_custom .section2 .portfolio-caption h2,
#main_custom .section3 .portfolio-caption h2,
#main_custom .section4 .portfolio-caption h2 {
  font-size: 1.5625rem;
  margin: 10px 0 4px;
  font-weight: 500; }
#main_custom .portfolio-item:hover,
.home__img_marg img:hover{
  opacity: .8;
  cursor: pointer;
}
.portfolio-item .far.off{
  margin: 0 2px 15px;
}
.home__img_marg.no__hover img:hover{
  opacity: unset;
  cursor: unset;
}
#main_custom .section2 .portfolio-caption p,
#main_custom .section3 .portfolio-caption p,
#main_custom .section4 .portfolio-caption p {
  font-size: 1.25rem;
  color: #000;
  line-height: 22px;
}
#main_custom .section2 .line_btn,
#main_custom .section3 .line_btn,
#main_custom .section4 .line_btn {
  margin-left: 100%;
  margin-right: 100%;
  margin-top: 34px; }
#main_custom .section2 .line_btn .btn_custom,
#main_custom .section3 .line_btn .btn_custom,
#main_custom .section4 .line_btn .btn_custom {
  height: 45px;
  text-transform: uppercase;
  padding: 8px 40px 4px;
  color: #fff;
  margin-top: -59px;
  width: auto;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 28px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;
}
.btn_custom:focus,
.btn_custom:hover {
  background-color: #595959;
  color: #fff;
}
#main_custom .section2 .min_font h2,
#main_custom .section3 .min_font h2,
#main_custom .section4 .min_font h2 {
  font-size: 1rem;
  font-weight: 300;
  min-height: 44px; }
#main_custom .sep {
  margin: 5% auto; }

.history {
  position: relative; }
.history:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 4px;
  margin: 0 auto;
  background-image: url(../img/Timeline/linea-hitos.png);
  background-position: bottom center;
  background-repeat-x: no-repeat; }
.history [class*=col-] {
  float: left;
  width: 100%;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }
.history .row_custom_small {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.history .row_custom_small:before {
  content: " ";
  display: table; }
.history .row_custom_small:after {
  display: block;
  clear: both;
  content: ''; }
.history .c-r {
  float: right; }
.history .c-l.type-txt {
  text-align: right; }
.history .c-l.type-num {
  text-align: right;
  padding-right: 51px; }
.history .type-txt h2 {
  font-size: 2.625rem;
  line-height: 42px;
  font-family: "Freeland",Arial,Verdana;
  margin-bottom: 16px; }
.history .type-txt p {
  font-size: 1rem;
  line-height: 30px;
  font-weight: 300;
  font-family: "HelveticaNeueLTStd",Arial,Verdana; }
.history .type-txt > div {
  width: 310px;
  display: inline-block;
  width: 100%;}
.history .type-num {
  padding-left: 54px;
  padding-top: 24px;
  padding-bottom: 20px;
  color: #9fba5d; }
.history .type-num p {
  padding-top: 20px;
  font-size: 2.625rem;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  font-family: "Freeland",Arial,Verdana; }

.header_maps {
  padding: 80px 0;
  background-image: url(../img/Storelocator/hero-salon.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
.header_maps .maps_salons {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  margin: auto;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }
.header_maps .maps_salons h1 {
  font-family: "Freeland",Arial,Verdana;
  font-size: 2.625rem;
  margin: 50px 0 40px; }
.header_maps .maps_salons .bar-cn > * {
  margin: 0 10px 20px;
  vertical-align: middle; }
.header_maps .maps_salons .bar-cn .btn_custom_oro {
  width: 240px;
  vertical-align: middle;
  font-size: 1.125rem;
  font-weight: 300;
  height: 42px;
  text-transform: uppercase;
  background-color: #dfc08c;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border: none;
  border-radius: 0%; }
.header_maps .maps_salons .bar-cn .form_custom_maps {
  display: inline-block; }
.header_maps .maps_salons .bar-cn .form_custom_maps input {
  display: inline-block;
  margin-right: -5px;
  background-color: transparent;
  border: 1px #000 solid;
  padding: 10px 12px 8px;
  font-size: .9375rem;
  color: #000;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0; }
.header_maps .maps_salons .bar-cn .form_custom_maps button {
  font-size: 1.125rem;
  font-weight: 300;
  height: 42px;
  text-transform: uppercase;
  background-color: #dfc08c;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border-radius: 0;
  margin: -4px 0 0 0; }

.link_social a {
  transition: all .3s ease; }
.link_social a:hover {
  text-decoration: none;
  opacity: .6; }

.link_share a {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 48px;
  text-align: center;
  vertical-align: middle;
  border: 2px solid #000;
  color: #000;
  font-size: 1.5rem;
  margin-right: 20px;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%; }
.link_share a:hover {
  color: #9fba5d;
  border-color: #9fba5d; }
.link_share a:hover i {
  color: #9fba5d; }
.link_share a i {
  padding-top: 8px; }

.row_custom__richtext {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.row_custom__richtext p {
  font-size: .9375rem;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 300; }
.row_custom__richtext .padding_custom {
  padding: 20px; }
.row_custom__richtext .padding_custom h1 {
  margin: 34px 0 20px;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 42px;
  font-weight: 700; }
.row_custom__richtext .custom_h1,
.row_custom__richtext .custom_title {
  color: #9fba5d;
  margin: 0 0 20px;
  font-size: 2.25rem;
  text-transform: uppercase;
  line-height: 42px; }
.row_custom__richtext .custom_h1 strong,
.row_custom__richtext .custom_title strong {
  border-bottom: 3px solid #9fba5d;
  font-weight: 700; }
.row_custom__richtext .last_margin {
  margin: 0 0 40px; }
.row_custom__richtext .custom_title {
  margin: 22px 0 22px;
  line-height: 50px; }

/*.related__product__custom {*/
/*  margin-top: 6%; }*/
.related__product__custom .pad {
  padding: 20px; }
.related__product__custom .pad h1 {
  font-size: 2.625rem;
  font-family: "Freeland",Arial,Verdana;
  text-align: center;
  /*font-weight: bold;*/
}
.related__product__custom .portfolio-caption{
  margin-bottom: 1em;
  height: auto; }
.related__product__custom .portfolio-caption h2{
  line-height: 23px;
  display: block;
  font-size: 15px;
  font-weight: 300;
  height: auto;
  margin-bottom: 0;
  padding: 0 42px;}
.related__product__custom .portfolio-caption p {
  font-size: 1.25rem;
  margin-top: 2px;
}
.related__product__custom .line_btn {
  margin-left: 100%;
  margin-right: 100%;
  margin-top: 34px; }
.related__product__custom .btn_custom_oro {
  text-transform: uppercase;
  font-size: 1rem;
  height: 40px;
  line-height: 44px;
  padding: 0 10px;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff;
  border-radius: 0;
  width: 65% !important;
  margin-top: 5%;
  z-index: 10;
  position: relative;
}
.related__product__custom .btn_custom_oro:hover {
  background-color: #595959;
  color: #fff; }
.related__product__custom .prod__item:focus,
.related__product__custom .prod__item:hover {
  box-shadow: 0 2px 10px rgba(198,198,198,.5);
}
.related__product__custom .portfolio-link:hover {
  cursor: pointer; }
.related__product__custom .prod__item{
  position: relative;
  margin-bottom: 26px;
}
.related__product__custom .portfolio-link{
  display: block;
  text-align: center;
  color: #000;
  padding-bottom: 16px;
  margin-bottom: -48px;
  height: 382px;
}
.related__product__custom .ratedCustom h1{
  margin-bottom: -12px
}
.related__product__custom .customWidth{
  width: 42% !important;
}
.related__product__custom .portfolio-item{
  opacity: initial !important;
}
.related__product__custom .portfolio-linkCustom .img-fluid{
  height: 215px !important;
  width: 215px !important;
}
.custom_margin{
  margin-bottom: 10px;
}
.related__product__custom .prod__item i{
  font-size: .875rem;
  margin: 0 4px 20px;
  color: #000;
}
.related__product__custom i.fa.fa-star.off:before {
  color: #d6d6d6;
  font: normal normal normal 14px/1 FontAwesome;
}
.fa.fa-star,
.fa.fa-star.off,
.fa.fa-star-half-o.off{
  font-size: .855rem;
  margin: 0 2px 15px;
}
.fa-star.off:before {
  color: #d6d6d6;
  font: normal normal normal 14px/1 FontAwesome;
}
.fa{
  margin: 0 2px 15px;
  font: normal normal normal 14px/1 FontAwesome;
}
.comment-text .fa{
  margin: unset;
  margin-right: 5px;
}
.ingre_modal .close{
  position: initial;
}
.title-review {
  text-align: center;
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland",Arial,Verdana;
  width: 100%; }

.same__font {
  font-size: .875rem; }
.same__font i:before{
  font-family: 'FontAwesome';
  color: #d6d6d6;
  transition: all .3s ease;
}
.another__font {
  font-size: 1.375rem; }

#reviews {
  margin-top: 34px; }
#reviews p {
  margin-bottom: 10px; }
#reviews #comments .Reviews-title {
  display: none; }
#reviews #comments .noreviews {
  line-height: 30px; }
#reviews #review_form_wrapper .comment-reply-title {
  line-height: 22px; }
#reviews #review_form_wrapper .comment-form {
  line-height: 30px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating {
  line-height: 22px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars {
  display: inline-block;
  margin-left: 8px;
  line-height: 30px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a {
  padding: 0 4px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:hover {
  text-decoration: none; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:focus > i, #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:active > i {
  color: #000; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:before {
  transition: all .3s ease; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a i:before {
  font-family: 'FontAwesome';
  color: #000;
  transition: all .3s ease;
}
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a i.off:before{
  color: #d6d6d6;
}
.min_font .fa-star:before{
  margin: unset;
}
#reviews #review_form_wrapper .comment-form .comment-form-comment {
  line-height: 30px; }
#reviews #review_form_wrapper .comment-form .comment-form-comment textarea {
  resize: none;
  width: 100%;
  height: 166px;
  background-color: transparent;
  border: 1px #000 solid;
  padding: 12px 12px 8px;
  font-size: .9375rem;
  color: #000;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  outline: 0;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  overflow: auto;
  vertical-align: top;
  margin: 0; }
#reviews #review_form_wrapper .comment-form .comment-form-comment textarea:hover, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:focus, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:active {
  background-color: rgba(0, 0, 0, 0.02);
  border-color: #477824; }
#reviews #review_form_wrapper .comment-form .input__nombre {
  width: 100%;
  margin: 0 !important; }
#reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author {
  width: 100%;
  margin-bottom: 0; }
#reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input {
  width: 100%;
  background-color: transparent;
  border: 1px #000 solid;
  padding: 3px 10px 3px;
  font-size: 0.873rem;
  color: #000;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  outline: 0;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0; }
#reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:hover, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:focus, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:active {
  background-color: rgba(0, 0, 0, 0.02);
  border-color: #477824; }
#reviews #review_form_wrapper .comment-form .pprivacy {
  font-size: .875rem;
  line-height: 22px;
  margin-top: 10px; }
#reviews #review_form_wrapper .comment-form .pprivacy .privacyBox {
  position: relative;
  margin-right: 10px;
  margin-top: 3px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  float: left; }
#reviews #review_form_wrapper .comment-form .pprivacy a {
  font-weight: 700;
  color: #000; }
#reviews #review_form_wrapper .comment-form .form-submit {
  margin-top: 28px; }
#reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro {
  height: 36px;
  text-transform: uppercase;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;}
#reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro:hover,
.btn_custom:hover{
  background-color: #595959 !important;
  color: #fff !important; }
#reviews #review_form_wrapper .Legal-form {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px; }
#reviews #review_form_wrapper .Legal-form p {
  margin: unset;
  font-size: 12px;
  letter-spacing: unset; }
#reviews #review_form_wrapper .Legal-form ul {
  padding: 0; }
#reviews #review_form_wrapper .Legal-form ul li {
  list-style: none;
  font-size: .75rem;
  line-height: 22px;
  font-family: "Helvetica Lt", Arial, Verdana;
}
#reviews #review_form_wrapper .Legal-form ul li a {
  color: #000;
  text-decoration: underline; }

.list__product__item {
  display: block;
  text-align: center;
  margin-top: 20px;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.list__product__item img {
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }
.list__product__item .star-cn {
  margin-top: 1rem;
  height: 24px;}
.list__product__item .off,
.prod__item .off{
  color: #d6d6d6 !important;
  font-size: .875rem; }
.list__product__item .colo__star {
  color: #000; }
.list__product__item .Producto-item {
  float: none;
  margin: 0 -2px;
  display: inline-block;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  height: 450px !important; }
.list__product__item .Producto-item button {
  overflow: visible;
  padding: 0;
  background: 0 0;
  border: 0; }
.list__product__item .Producto-item .plus__custom{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 22px;
  height: 25px;
  transition: all .3s ease;
  cursor: pointer; }
.list__product__item .link__custom__item {
  display: block;
  text-align: center;
  color: #000;
  padding-bottom: 16px; }
.list__product__item .link__custom__item:hover {
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(198, 198, 198, 0.5); }
.list__product__item .link__custom__item .title-cn {
  font-size: 1rem;
  min-height: 48px;
  margin-bottom: 10px;
  font-weight: 300;
  display: block;
}
.list__product__item .link__custom__item .btn_custom_oro {
  height: 40px;
  line-height: 46px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #dfc08c;
  border: 0;
  -webkit-border-radius: 0%;
  -moz-border-radius: 0%;
  border-radius: 0%;
  color: #fff;
  padding: 0 18px;
  font-weight: 300; }
.list__product__item .link__custom__item .btn_custom_oro:hover {
  background-color: #595959;
  color: #fff; }
.list__product__item .Producto-item .far.off{
  margin: 0 2px 15px;
}

.modal-xl {
  max-width: 900px !important; }
.modal-xl .modal-content {
  padding: 8px 5px 20px; }
.modal-xl .modal-content .star-cn {
  margin-top: 0; }
.modal-xl .modal-content .modal-header {
  padding-bottom: 0;
  margin-bottom: -20px;
  border: none !important; }
.modal-xl .modal-content .modal-header .close {
  z-index: 2050;
  background-color: #fff !important;
  width: 47px;
  height: 47px;
  line-height: 47px;
  color: #000;
  font-size: 2.625rem;
  text-align: center;
  padding: 0;
  top: 6px;
  right: 6px; }
.modal-xl .modal-content img {
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }
.modal-xl .modal-content .popup-title_item {
  font-size: 2.25rem;
  margin-top: 10px;
  font-weight: 700;
  margin-bottom: 10px; }
.modal-xl .modal-content .litro__custom {
  font-size: 1.0625rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 18px;
  display: block; }
.modal-xl .modal-content .pop__description {
  font-size: .875rem;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 30px; }
.modal-xl .modal-content p {
  font-size: .9375rem;
  line-height: 24px;
  font-weight: 400; }
.modal-xl .modal-content .btn_custom_oro {
  height: 40px;
  line-height: 46px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #dfc08c;
  border: 0;
  -webkit-border-radius: 0%;
  -moz-border-radius: 0%;
  border-radius: 0%;
  color: #fff;
  padding: 0 18px;
  font-weight: 300; }
.modal-xl .modal-content .btn_custom_oro:hover {
  background-color: #595959 !important;
  color: #fff !important; }
.modal .modal-content .close{
  z-index: 2;
}
.custom_list h1 {
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland", Arial, Verdana; }
.custom_list{
  background: #dfc08c42;
}
.custom_list .list_ingredients > * {
  float: none;
  margin: 0 -2px; }
.custom_list .list_ingredients{
  display: inline-flex;
}
.custom_list .list_ingredients .desc{
  margin: 16px 87px !important;
}
.custom_list .list_ingredients .col-md-4 {
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  display: inline-block;
  font-family: "HelveticaNeueLTStd",Arial,Verdana; }
.custom_list .list_ingredients .col-md-4 img {
  margin: 0 35px 20px; }
.custom_list .list_ingredients .col-md-4 .name_ing_custom {
  font-weight: 700; }
.custom_list .list_ingredients .col-md-4 p {
  margin-top: 6px; }
/*.custom_list .btn_div_custom {*/
/*  margin: 40px 0; }*/
.custom_list .btn_div_custom .btn {
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border-radius: 0; }
.custom_list .btn_div_custom a {
  font-size: 1.375rem;
  text-transform: uppercase;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff;
}
.custom_list .btn_div_custom a:hover {
  background-color: #595959; }

.list_cn {
  padding-left: 15px;
  padding-right: 15px; }
.list_cn .link_ingredient {
  padding: 20px;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
  transition: all .3s ease; }
.list_cn .link_ingredient:hover {
  text-decoration: none; }
.list_cn .link_ingredient:focus >div,
.list_cn .link_ingredient:hover>div {
  background-color: #d0d3c2;
}
.list_cn .link_ingredient > div {
  min-height: 355px;
  background-color: #dee0d4;
  padding: 12px;
  transition: all .3s ease; }
.list_cn .link_ingredient > div .img-cn {
  text-align: center;
  display: block;
  background-color: #fff; }
.list_cn .link_ingredient > div .img-cn img {
  max-height: 242px;
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }
.list_cn .link_ingredient .title-cn {
  text-align: center;
  font-size: 1.675rem;
  font-family: Freeland,Arial,Verdana;
  display: block;
  margin: 32px 0 0;
  font-weight: 500; }
.list_cn .modal-xl {
  max-width: 1055px; }
.list_cn .modal-xl .modal-content .modal-header {
  border-bottom: unset;
  padding-bottom: 0;
  margin-bottom: -30px; }
.list_cn .modal-xl .modal-content .modal-header .close {
  z-index: 2050; }
.list_cn .modal-xl .modal-content .popup-title {
  font-size: 1.375rem;
  margin-top: 50px;
  padding-right: 40px;
  font-weight: 700;
  margin-bottom: 10px; }
.list_cn .modal-xl .modal-content p {
  font-size: .9375rem;
  line-height: 24px;
  font-weight: 400; }


@media (min-width: 350px) {
  .slicknav_menu {
    top: 46px; } }
@media (min-width: 1005px) {
  .slicknav_menu {
    display: none !important; } }
.all_category_product h1 {
  padding-left: 20px;
  font-size: 1.125rem;
  margin-bottom: 20px; }
.all_category_product .row_custom_small {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.all_category_product .row_custom_small .col-md-12,
.all_category_product .row_custom_small .col-md-6 {
  float: left;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }
.all_category_product .row_custom_small .col-md-12 h1,
.all_category_product .row_custom_small .col-md-6 h1 {
  color: #9fba5d;
  font-size: 4.6875rem;
  text-transform: uppercase;
  font-family: "PopulaireLight",Arial,Verdana;
  line-height: 54px;
  margin-bottom: unset; }
.all_category_product .list_cat_product__custom {
  margin-top: 20px; }
.all_category_product .list_cat_product__custom .item_product_category {
  color: #000;
  text-align: center;
  height: 530px;}
.all_category_product .list_cat_product__custom .item_product_category:hover {
  opacity: .8;
  text-decoration: none; }
.all_category_product .list_cat_product__custom .item_product_category img {
  display: block;
  margin: 0 auto 10px;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  outline: none;
  height: 370px !important;
  width: 370px;
  height: 370px !important;
}
.all_category_product .list_cat_product__custom .item_product_category h2 {
  font-size: 1.375rem;
  font-weight: 300;
  margin-bottom: unset; }
.all_category_product .list_cat_product__custom .item_product_category p {
  font-size: 1.125rem;
  line-height: 30px;
  margin-bottom: 18px;
  color: #000;
  text-align: center;
  font-weight: 300; }
.all_category_product .list_cat_product__custom .item_product_category .btn-oro {
  text-transform: uppercase;
  font-size: 1.25rem;
  background-color: #dfc08c;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  border: none;
  border-radius: unset;
  overflow: visible; }
.all_category_product .list_cat_product__custom .item_product_category .btn-oro:hover {
  background-color: #595959;
  color: #fff; }
.all_category_product .Separa-cn {
  margin: 20px 0 40px;
  text-align: center; }

.breadcrumb__custom {
  font-size: .875rem;
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 700;
  text-align: left;
  overflow: auto;}
.breadcrumb__custom a {
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  color: #000;
  font-weight: 400; }
.breadcrumb__custom a:hover {
  color: #477824;
  text-decoration: none; }

.item__row__custom {
  position: relative;
  max-width: 822px;
  margin: 0 auto; }
.item__row__custom .c-r {
  float: right;
  padding: 10px; }
.item__row__custom .c-r .text-cn > div {
  background-color: #fff; }
.item__row__custom .text-cn {
  top: 0;
  bottom: 0; }
.item__row__custom .text-cn > div {
  /*position: absolute;*/
  height: 100%;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }
.item__row__custom .text-cn > div > div {
  padding: 20px; }
.item__row__custom .c-l,
.item__row__custom .c-r {
  padding: 10px; }
.item__row__custom .c-l.text-cn {
  text-align: right; }
.item__row__custom .c-l.text-cn > div {
  background-color: #f8e5ca; }
.item__row__custom h1 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 2.625rem;
  font-family: "Freeland",Arial,Verdana;
  line-height: 40px; }
.item__row__custom p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 300;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  color: #000; }
.item__row__custom img {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  outline: none;
  height: 100% !important;}

.title__custom__articles {
  font-size: 4rem;
  font-family: "PopulaireLight",Arial,Verdana; }

.custom__title_article h1{
  font-size: 3.375rem;
  font-weight: 300;
  margin: 32px 0;
  text-transform: uppercase;
  color: #9fba5d;
  font-family: "PopulaireLight",Arial,Verdana;
}
.dateCustom{
  margin: -10% 0 14% 0;
  font-size: 1.125rem;
  font-family: 'HelveticaNeueLTStd', Arial, Verdana;
  font-size: 18px;
}
.cat__custom__article ul {
  padding-left: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  vertical-align: baseline;
  list-style: none; }
.cat__custom__article ul li {
  width: 24%;
  margin-bottom: 10px; }
.cat__custom__article ul li a {
  justify-content: center;
  width: 100% !important;
  height: 150px;
  color: #000 !important;
  font-size: .875rem !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: unset;
  border: none !important;
  border-radius: unset !important;
}
.cat__custom__article ul li a span {
  display: block;
  width: 176px;
  height: 26px;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.86);
  margin: 22% 0 22% 25%; }
.cat__custom__article ul li a span {
  width: 116px;
  height: 32px;
  line-height: 33px;
  margin: 50% 0 50% 56%;
}
.cat__custom__article ul li a span {
  display: block;
  width: 176px;
  height: 26px;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.86);
  margin: 55px auto;
  }

.all-articles__custom {
  padding-left: unset !important;
  padding-right: unset !important; }
.all-articles__custom:first-child{
  padding-top: 0px !important;
}
.all-articles__custom .item__article__only {
  height: auto;
  position: relative;
  color: #000;
  padding-bottom: 80px;
  margin-bottom: 30px; }
.all-articles__custom .item__article__only:hover {
  transform: translateY(-20px);
  text-decoration: none; }
.all-articles__custom .item__article__only:hover button{
  background-color: #595959;
  color: #fff;
}
.all-articles__custom .item__article__only:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: 0;
  height: 4px;
  background-color: rgba(155, 155, 155, 0.2);
  transition: all .3s ease; }
.all-articles__custom .item__article__only:hover:before {
  width: 75%; }
.all-articles__custom .item__article__only img {
  display: block;
  margin: 0 auto; }
.all-articles__custom .item__article__only h1 {
  display: block;
  min-height: 90px;
  font-size: 1.5625rem;
  margin: 25px 0 20px; }
.all-articles__custom .item__article__only p {
  font-size: 1rem;
  min-height: 76px;
  font-weight: 300;
  margin-bottom: 16px;
}
.all-articles__custom .item__article__only button {
  font-size: 1.375rem;
  /* position: absolute;
  bottom: 20px;
  left: 20px; */
  text-transform: uppercase;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff;
  border-radius: 0%;
  /* margin: 0% 18%; */
}

.pagenavi {
  font-size: 1.125rem; }
.pagenavi span,
.pagenavi a {
  position: relative;
  padding: 5px 5px 4px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  background-color: #dfc08c;
  margin: 0 1px;
  min-width: 26px;
  height: 29px;
  font-size: .875rem; }
.pagenavi span:hover,
.pagenavi a:hover {
  text-decoration: none;
  color: #fff;
  cursor: pointer;}
.pagenavi a.nextpostslink {
  margin-right: 0; }
.pagenavi span.current {
  font-weight: 600;
}

.text_notfound p {
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  font-weight: 500; }
.text_notfound p:first-child {
  margin: -6px 0; }

.display_my_img_txt {
  position: relative; }
.display_my_img_txt .c-r {
  float: right; }
.display_my_img_txt .c-r {
  float: right; }
.display_my_img_txt .c-l.img-cn {
  text-align: right; }
.display_my_img_txt .c-r.text-cn {
  right: 0; }
.display_my_img_txt .c-r.text-cn p {
  text-align: left; }
.display_my_img_txt .c-l.text-cn p {
  text-align: right; }
.display_my_img_txt .text-cn {
  top: 0;
  bottom: 0; }
.display_my_img_txt .text-cn > div {
  position: absolute;
  bottom: 0;
  text-align: right;
  left: 10px;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center; }
.display_my_img_txt .text-cn p {
  max-width: 538px;
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 30px;
  font-weight: 300; }
.display_my_img_txt .text-cn p img {
  width: 538px; }
.display_my_img_txt .c-l,
.display_my_img_txt .c-r {
  padding: 20px; }
.display_my_img_txt .c-l.text-cn {
  text-align: right; }
.display_my_img_txt p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 300;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  color: #000; }
.display_my_img_txt img {
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }

/*# sourceMappingURL=styles.css.map */
.up_index{
  z-index: 999;
}
.wrapper {
  display: block;
  margin: 16px 0 40px;
}
.custom__height{
  height: 70px;
  text-align: center;
}
.main-hd .menu-hd>li>.sub-menu>li.col__5:nth-child(2) li {
  width: 50%;
  display: inline-block;
  margin: 0 -2px;
  padding: 0 10px 0 0;
}
.home__img_marg{
  margin: 40px 0 20px;
  text-align: center;
}

/* Search */
.search__custom{
  margin-bottom: 160px;
  height: 447px;
}
.search__custom .row_custom_small{
  padding: 80px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.search__custom .col__custom{
  text-align: center;
  background-color: rgba(255,255,255,.9);
}
.search__custom .col__custom:last-child{
  margin: auto!important;
}
.search__custom h1{
  font-size: 2.625rem;
  margin: 50px 0 40px;
  font-family: "Freeland",Arial,Verdana;
}
.search__custom form{
  margin-bottom: 50px;
  text-align: center;
}
.search__custom form input{
  display: inline-block !important;
  margin-right: 22px;
}
.search__custom input[type=email],
.search__custom input[type=password],
.search__custom input[type=tel],
.search__custom input[type=text],
select, textarea {
  background-color: transparent;
  border: 1px #000 solid;
  padding: 12px 12px 8px;
  font-size: .9375rem;
  color: #000;
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  outline: 0;
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
}
.search__custom form button {
  cursor: pointer;
  font-size: 1.25rem;
  transition: all .3s ease;
  overflow: visible;
  padding: 0;
  background: 0 0;
  border: 0;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
}

.video__pad{
  padding: 0 20px 13px !important;
  height: 530px;
}

.video__pad .embed-responsive{
  height: 100%;
}

.custom_row_block{
  position: relative;
  max-width: 822px;
  margin: 0 auto;
}
.custom_row_block .row_custom_small+.row_custom_small{
  margin-top: unset;
}

.row-article-layout .cat__custom__article ul {
  display: block;
}

.row-article-layout .cat__custom__article ul li {
  width: 100%;
}

.row-article-layout .cat__custom__article ul li a span {
  margin: 20% 0 20% 18%;
}

.featured_posts_custom_heading h1{
  font-size: 4.6875rem;
  color: #9fba5d;
  text-align: center;
  font-family: "PopulaireLight",Arial,Verdana;
  text-transform: uppercase;
  margin-bottom: 26px;
}
.featured_posts_custom_heading blockquote{
  max-width: 990px;
  margin-bottom: 40px;
  font-size: 1.375rem;
  quotes: none;
  margin: 24px auto 14px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  quotes: none;
}
.featured_posts_custom_heading p{
  margin-bottom: unset;
}
.featured_posts_custom{
  text-align: center;
  padding-bottom: 40px;
}
.featured_posts_custom h1{
  font-size: 2.625rem !important;
  font-family: "Freeland",Arial,Verdana;
  margin-top: 10px !important;
}
.featured_posts_custom h2{
  font-size: 1.4375rem;
  line-height: 30px;
  font-weight: 300;
  margin: 0 0 16px;
  min-height: 60px;
}
.featured_posts_custom p{
  font-size: 1rem;
  line-height: 30px;
  min-height: 150px;
  font-weight: 300;
  margin: 12px 0 24px;
}
.featured_posts_custom a{
  text-transform: uppercase;
  width: 161px;
  height: 31px;
  font-size: 0.875rem;
}
.featured_posts_custom .btn{
  padding: 7px 10px 8px !important;
}
.featured_posts_cust{
  width: 320px !important;
  height: 320px !important;
}
.richtext_custom{
  font-size: .9375rem;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 300;
}
.cat__custom__article a:focus:before, .cat__custom__article a:hover:before {
  background-color: rgba(71,120,36,.9);
}
.product-categories li a{
  font-family: "Helvetica Lt", Arial, Verdana;
}
.category__summary__custom h1{
  font-size: 4.6875rem;
  font-family: "PopulaireLight",Arial,Verdana !important;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.category__summary__custom p{
  font-family: "Helvetica Lt", Arial, Verdana;
  font-size: 1.4375rem;
  line-height: 30px;
  font-weight: 300;
  max-width: 900px;
  margin: 0 auto;
}
.newRT{
  font-size: .9375rem;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 300;
}
.cat__custom__article a:hover{
  background-color: rgba(71,120,36,.9);
}
time{
  color: #bebebe;
  display: block;
  margin: 12px 0 -28px;
  font-family: "Helvetica Md", Arial, Verdana !important;
  font-size: 16px;
}
time.time_article {
  display: inline-block;
}
.all-articles__custom button{
  font-family: "Helvetica Lt", Arial, Verdana !important;
}
.all-articles__custom .item__article__only h1{
  font-family: "Helvetica Std", Arial, Verdana !important;
}
.title__custom__articles small{
  font-size: 3rem;
  font-family: "PopulaireLight", Arial, Verdana;
}
.modal_popin_up{
  overflow: scroll;
  backdrop-filter: blur(20px);
  background: #000000ad;
}
.modal_popin_up .modal{
  height: auto !important;
}
.modal_popin_up .modal-header{
  border-bottom: 0px !important;
  padding: 0px !important;
}
.modal_popin_up .modal-header .close{
  text-shadow: unset !important;
  opacity: 1;
}

.modal_popin_up .modal .modal-content .close:hover{
  color: #fff !important;
}
.modal_popin_up{
  overflow: unset;
}
.no__hover .home__img_marg img:hover{
  cursor: initial !important;
}
.header__article__cus{
  font-size: 3.375rem;
  font-weight: 300;
  margin: 32px 0;
  text-transform: uppercase;
  color: #9fba5d;
}
.header__article__cus h1{
  font-size: 1.125rem;
  font-weight: 700;
}
.modal_popin_up .modal-content .close span {
  position: absolute;
  right: -135%;
  top: -8%;
  font-size: 3rem;
  z-index: 2;
  color: #f0f0f0;
}
.backmodal{
  backdrop-filter: blur(20px);
  background: rgba(0,0,0,.6);
}
.text_notfound p {
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  font-weight: 500; }
.text_notfound p:first-child {
  margin: -6px 0; }

.list_cn .modal.fade.show{
  z-index: 2;
  background: #ffffffd4;
}
.modal-backdrop.fade.show{
  display: none;
}

.carousel .slider-wrapper,
.carousel .slider-wrapper.axis-horizontal .slider,
.carousel .slider.animated li div,
.carousel .slider.animated li div img,
.carousel .thumb.selected img
{
  height: auto !important;
}

.thumbs-wrapper ul{-webkit-padding-start: unset; padding-inline-start: unset;}
.thumbs-wrapper.axis-vertical{padding-top: 5px;}
/* Root on load display */
#root{
  opacity: 0;
  transition: all .5s ease-in-out;
}

.is-loaded {
  opacity: 1 !important;
}


a.portfolio-link, a.portfolio-link:hover  {
  text-decoration: none;
  color: unset;
}
/*<div className={'rounded_rated_prod'}></div>*/
/*.rounded_rated_prod{*/
/*  width: 235px;*/
/*  height: 235px;*/
/*  background-color: #b1b1b11f;*/
/*  border-radius: 50%;*/
/*  z-index: 2;*/
/*  position: absolute;*/
/*}*/
/* CLEAN CSS */
.sweet-loading{
  margin-top: 50px;
  margin-bottom: 50px;
}
.modal .modal-content.modal-content-newsletter {
  width: 500px;
  height: 780px;
}
.main-ft{
  background-color: rgba(155,155,155,.2);
  padding: 58px 0 30px;
}

.main-ft .row_custom_small:after,
.main-ft .row_custom_small:before {
  content: " ";
  display: table;
}

.main-ft .top-ft{
  height: auto;
}

.main-ft .logo {
  width: 278px;
  height: 30px;
}

.link_social>* {
  margin-right: 6px;
}
.main-ft .link_social {
  margin-top: -10px;
}

.link_stext {
  font-size: 2.125rem;
  font-family: "Freeland",Arial,Verdana;
  display: block;
  margin: 36px 0 26px;
  font-weight: 500;
}

.link_contact {
  margin-top: 6px;
}

.link_contact a {
  display: inline-block;
  margin-bottom: 6px;
  color: #000;
  font-size: .9375rem;
  font-weight: 300;
}

.link_contact a i {
  color: #949494;
  margin-right: 6px;
}

.main-ft .bottom-ft{
  height: 58px;
}

.main-ft .bottom-ft p {
  text-align: left;
  font-size: .9375rem;
}

.main-ft .bottom-ft a,
.main-ft .bottom-ft span {
  display: inline-block;
  margin: 0 12px;
}

.main-ft .bottom-ft a {
  position: relative;
  color: #000;
}

.main-ft .bottom-ft a:before {
  content: '';
  position: absolute;
  top: -5px;
  bottom: 0;
  left: -12px;
  width: 1px;
  background-color: #000;
}

.main-ft .bottom-ft a:focus,
.main-ft .bottom-ft a:hover,
.sidebar_bot a:hover{
  color: #477824;
  text-decoration: none;
  cursor: pointer;
}

.sidebar_bot li{
  list-style: none;
}
.sidebar_bot a{
  color: #000;
}

.filterSe{
  position: absolute;
  top:0;
  right:0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 5;
}
.btn_custom_green{
  padding: 13px 18px 8px !important;
  background-color: #9fba5d;
  color: #fff !important;
}
.btn_custom_green2{
  padding: 13px 18px 8px !important;
  color: #9fba5d !important;
  margin: 0 auto !important;
  margin-top: 2% !important;
  font-weight: 900;
}
.fontCustomNav{
  font-size: 1.5rem !important;
}
.vid_cust{
  width: 100%;
  height: 575px;
}
.overSlide{
  position: absolute;
  width: 32%;
  height: auto;
  z-index: 1;
  top: 50%;
  left: 2%;
  padding: 2% 4%;
  background: #fffff6ed;
}
.overSlide h2{
  font-size: 1.35rem;
}
.overSlide h2{
  font-size: 15px;
  margin-bottom: 14px;
}

.relatedProd,
.bestrated,
.relatedProd .owl-stage-outer,
.bestrated .owl-stage-outer,
.relatedProd .owl-stage,
.bestrated .owl-stage{
  width: 100%;
}
.relatedProd .owl-stage,
.bestrated .owl-stage{
  display: inline-flex;
  height: auto;
}
.owl-carousel.owl-drag .owl-item {
  height: auto;
}
.owl-stage{
  height: 375px;
}
.relatedProd,
.bestrated{
  overflow: hidden;
  margin: 0 auto;
}
.relatedProd .owl-stage-outer,
.bestrated .owl-stage-outer{
  height: auto;
  overflow: hidden;
}
.arround_grey{
  position: absolute;
  height: 186px;
  width: 186px;
  background: #b5b5b512;
  border-radius: 50%;
  margin: 10px 16px;
  z-index: 1;
}
.relatedProd .owl-item,
.bestrated .owl-item,
.relatedProd .portfolio-item,
.bestrated .portfolio-item{
  height: auto;
}
.relatedProd .portfolio-item:hover,
.bestrated .portfolio-item:hover{
  cursor: initial;
}
.relatedProd .portfolio-link,
.bestrated .portfolio-link{
  height: auto;
}
.bestrated .owl-nav,
.relatedProd .owl-nav,
.bestrated .owl-prev,
.relatedProd .owl-prev,
.bestrated .owl-next,
.relatedProd .owl-next,
.bestrated .owl-dots,
.relatedProd .owl-dots{
  display: none;
}
button.btn.chevrons_bestrated.next ,
button.btn.chevrons_bestrated.prev {
  background: #fff !important;
  color: #000 !important;
  border: none !important;
}
button.btn.chevrons_bestrated.next:focus,
button.btn.chevrons_bestrated.prev:focus {
  box-shadow: none;
}
.relatedProd .portfolio-link h2,
.bestrated .portfolio-link h2{
  padding: 0 30px;
}
.placeholderCustom{
  border-radius: 50%;
  height: 186px;
  width: 186px;
}
.relatedProd img,
.bestrated img{
  width: 200px;
  height: 200px !important;
}
.relatedProd .portfolio-caption,
.bestrated .portfolio-caption{
  height: 110px;
  margin-top: 8px;
}
.customPopUpBestrated{
  background: #ffffffde;
}
body.modal-open{
  padding-right: 0 !important;
}
.relatedProd .plus__custom,
.bestrated .plus__custom{
  position: absolute;
  width: 83px;
  height: 20px;
  top: 18px;
  padding-left: 28px;
  z-index: 25;
}
.relatedProd .plus__custom:hover,
.bestrated .plus__custom:hover{
  cursor: pointer;
}
.pageTitleRelated h1{
  font-size: 1.3em !important;
  font-family: "Helvetica Std" !important;
  font-weight: bold;
}
.pagenavi a, .pagenavi span{
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;
}

.dropdownCustom .dropdown-menu .dropdown-item img {
  width: 100%;
  height: 47px;
}
.bioCarousel{
  height: 500px;
}
.bioCarousel iframe,
.bioCarousel img{
  height: 500px !important;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  -webkit-border-radius: 0 6px 6px 6px;
  -moz-border-radius: 0 6px 6px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  -webkit-border-radius: 6px 0 6px 6px;
  -moz-border-radius: 6px 0 6px 6px;
  border-radius: 6px 0 6px 6px;
}
.noBackground{
  background: unset;
}
.custom_list.noBackground h2 {
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland", Arial, Verdana; }
.custom_list.noBackground .list_ingredients > * {
  float: none;
  margin: 0 -2px; }
.custom_list.noBackground .list_ingredients .col-md-4 {
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  display: inline-block;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  margin: 0 auto;
}
.custom_list.noBackground .list_ingredients .col-md-4 img {
  margin: 0 35px 20px; }
.custom_list.noBackground .list_ingredients .col-md-4 .name_ing_custom {
  font-weight: 700; }
.custom_list.noBackground .list_ingredients .col-md-4 p {
  margin-top: 6px; }
.custom_list.noBackground .btn_div_custom .btn {
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border-radius: 0; }
.custom_list.noBackground .btn_div_custom a {
  font-size: 1.375rem;
  text-transform: uppercase;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff; }
.custom_list.noBackground .btn_div_custom a:hover {
  background-color: #595959; }
.center-button {
  text-align: center;
	bottom: 20px;
}

.col-md-4.item__article__only .img_custom_full{
  width:320px;
  height:320px;
}

.row_custom_small.box-1.featured_posts_custom .col-md-4 .img_custom_full{
  width:320px;
  height:320px;
}

.row_custom_small.box-1.featured_posts_custom .col-md-4 .img_custom_full{
  width:320px;
  height:320px;
  }
  
.desc-cn .arrow .alignnone.size-full.wp-image-1241.img_custom_full{
  width:31px; 
  height:75px;
}

.col-md-12.custom__height .logo .img_custom_full{
  width:278px;
  height:30px;
}

.link__custom__item .img-cn .img_custom_full{
  width:600px; 
  height:600px;
}

.col-md-4.col-sm-12.col-xs-12 .img-fluid.img_custom_full{
  width:242px;
  height:242px;
}

.plus__custom .img_custom_full{
  width:22px;
  height:25px;
  }
  
  .Separa-cn .img_custom_full{
  width:598px;
  height:47px;
  }
  
  .col-md-7.c-r.type-num .img_custom_full{
  width:43px;
  height:43px;
  }
  
  .col-md-7.c-l.type-num .img_custom_full{
  width:43px;
  height:43px;
  }