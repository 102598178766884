/* -------------  CAROUSEL QUOTES ---------------------- */
.testimonial.style-4 .owl-dots .owl-dot:hover, .testimonial.style-4 .owl-dots .owl-dot.active {
    background: #9fba5d;
}
.testimonial.style-4 .owl-dots .owl-dot {
	display: inline-block;
	margin: 3px;
	width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c0bfbf;
}

.owl-dots {
    text-align: center;
}


/* --------------------------- HOME CAROUSEL ------------------------- */

.homeCarousel .owl-dots {
	left: 50%;
	top: 90%;
	text-align: center;
	position: absolute;
}

.homeCarousel .owl-carousel .owl-item {
	list-style-type: none;
}

.homeCarousel .owl-dots .owl-dot {
	left: -50%;
	display: inline-block;
	background: transparent;
	border: 1px solid #fff;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 10px;
	height: 10px;
	margin: 3px;
	position: relative;
	cursor: pointer;
}

.homeCarousel .owl-dots .owl-dot.selected, .homeCarousel .owl-dots .owl-dot.active, .homeCarousel .owl-dots .owl-dot:hover {
	background: #fff;
}


/* -------------  CAROUSEL BANNER PARTNERS ---------------------- */

.client-slider .owl-carousel {
	text-align: center;
	overflow:hidden;
}

.client-slider .owl-carousel .owl-stage-outer {
	display:inline-block;
}

.client-slider .owl-nav .owl-prev1::before {
	content: "\44";
	position: relative;
	display: inline-block;
	font-family: 'ElegantIcons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
}

.client-slider .owl-nav .owl-prev1 {
	display: block;
	left: 20px; 
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
	width: 40px;
	height: 40px;
	line-height: 44px;
	font-size: 20px;
	background-color: #9fce4e;
	border: 1px solid #9fce4e;
	text-align: center;
	z-index: 100;
	color: #fff;
	text-decoration: none;
	
	
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-webkit-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s;
}

.client-slider .owl-nav .owl-next1::before {
	content: "\45";
	position: relative;
	display: inline-block;
	font-family: 'ElegantIcons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
}

.client-slider .owl-nav .owl-next1 {
	display: block;
	right: 20px;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
	width: 40px;
	height: 40px;
	line-height: 44px;
	font-size: 20px;
	background-color: #9fce4e;
	border: 1px solid #9fce4e;
	text-align: center;
	z-index: 100;
	color: #fff;
	text-decoration: none;
	
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	-o-transform: translate(0, -50%);
	transform: translate(0, -50%);
	-webkit-transition: all .3s ease-out 0s;
	-o-transition: all .3s ease-out 0s;
	transition: all .3s ease-out 0s;
}

.client-slider .owl-nav .owl-next1:hover,  .client-slider .owl-nav .owl-prev1:hover {
	background: #fff;
	color: #9fce4e;
}

.client-slider li {
	width: 295px;
	text-align: center;
	float: left;
	padding: 0;
	list-style: none;
	margin: 0 10px;
	
}

.client-slider li a {
	color: #333;
	text-decoration: none;
	background: transparent;
	display: inline-block;
}

.client-slider li a img {
	width: auto	!important;
}


