#comments li{
    padding-bottom: 20px;
    margin-bottom: 28px;
    border-bottom: 1px solid #979797;
    border: 0;
    vertical-align: baseline;
    list-style: none;
    list-style-image: none;
}

#comments li .meta {
    margin-bottom: 10px;
}

#comments li .meta strong{
    font-size: .875rem;
    font-weight: 400;
}

#comments li .meta time{
    float: right;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 300;
}

#comments li p{
    line-height: 22px;
    font-size: 1.375rem;
    font-weight: 300;
    font-family: unset;
    margin-bottom: 10px;
}

#comments .star-rating{
    font-size: .75rem;
    margin-top: -10px;
}

#comments .star-rating i{
    margin-right: 5px;
}

#comments .description p{
    font-size: .875rem;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 24px;
}