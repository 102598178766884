@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);

.pagenavi {
  font-size: 1.125rem; }
  .pagenavi span,
  .pagenavi a {
    position: relative;
    padding: 5px 5px 4px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    background-color: #dfc08c;
    margin: 0 1px;
    min-width: 26px;
    height: 29px;
    font-size: .875rem; }
    .pagenavi span:hover,
    .pagenavi a:hover {
      text-decoration: none;
      color: #fff; }
  .pagenavi a.nextpostslink {
    margin-right: 0; }
  .pagenavi span.current {
    font-weight: 600;
    background-color: #595959; }

/*# sourceMappingURL=all_articles.css.map */


.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff; }

.carousel .control-next.control-arrow {
    display: none; }

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff; }

.carousel:hover > .control-prev.control-arrow{
    display: block;
    left:0;
}

.carousel:hover > .control-next.control-arrow  {
    display: block;
    right:0;
}

.carousel.carousel-slider:hover{
    cursor: default;
}

.thumbs-wrapper ul{
    -webkit-padding-start: 10px;
            padding-inline-start: 10px;
}
.carousel {
    position: relative;
    width: 100%; }
.carousel * {
    box-sizing: border-box; }
.carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none; }
.carousel .carousel {
    position: relative; }
.carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
}
.carousel .thumbs-wrapper {
    overflow: hidden;
}
.carousel .thumb {
    transition: border .15s ease-in;
    display: inline-block;
    margin-right: -4px;
    white-space: nowrap;
    overflow: hidden;
    opacity: .5;
    height: 100px;
    width: 100px;
}
.carousel .thumb.selected img{
    opacity: .8;
    border-color: #9FC66D;}
.carousel .thumb:hover img,
.carousel .thumb:focus img{
    opacity: .8;
    cursor: pointer;
    transition: all .3s ease;
    border-color: #9FC66D;}
.carousel .thumb.selected, .carousel .thumb:hover {
    border: unset;
}
.carousel .thumb img {
    vertical-align: top;
    transition: all .3s ease;
    border: 1px solid #9FC66D;
    height: 100% !important;}
.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden; }
.single-product-images .carousel.carousel-slider{
    width: 80% !important;
    margin: 0 40px;
}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in; }
.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex; }
.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column; }
.carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex; }
.carousel .slider-wrapper.axis-vertical .slider {
    flex-direction: column; }
.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%; }
.carousel .slider.animated {
    transition: all 0.35s ease-in-out; }
.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: unset !important;
}
.carousel .slide img {
    width: 100%;
    vertical-align: top;
}
.carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0; }
.carousel .slide .legend {
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    transition: opacity 0.35s ease-in-out; }
.carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%; }
@media (min-width: 960px) {
    .carousel .control-dots {
        bottom: 0; } }
.carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px; }
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100); }
.carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff; }
.carousel:hover .slide .legend {
    opacity: 1; }
.carousel.carousel-slider .control-arrow:hover {
    background: unset;
}
.carousel.carousel-slider .control-arrow {
    height: 0;
}

/* Sitecore Experience Editor For Product Images */
div.product-thumbnail img {
    transition: border .15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #e1e1e1;
    padding: 2px;
}
.margin_custom {
  margin-top: 2%; }

.title-review {
  text-align: center;
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland",Arial,Verdana;
  width: 100%; }

.same__font {
  font-size: .875rem; }

.another__font {
  font-size: 1.375rem; }

#reviews {
  margin-top: 34px; }
  #reviews p {
    margin-bottom: 10px; }
  #reviews #comments .Reviews-title {
    display: none; }
  #reviews #comments .noreviews {
    line-height: 30px; }
  #reviews #review_form_wrapper .comment-reply-title {
    line-height: 22px; }
  #reviews #review_form_wrapper .comment-form {
    line-height: 30px; }
    #reviews #review_form_wrapper .comment-form .comment-form-rating {
      line-height: 22px; }
      #reviews #review_form_wrapper .comment-form .comment-form-rating .stars {
        display: inline-block;
        margin-left: 8px;
        line-height: 30px; }
        #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a {
          padding: 0 4px; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:hover {
            text-decoration: none; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:focus > i, #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:active > i {
            color: #000; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:before {
            transition: all .3s ease; }
          #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a i {
            color: #d6d6d6; }
    #reviews #review_form_wrapper .comment-form .comment-form-comment {
      line-height: 30px; }
      #reviews #review_form_wrapper .comment-form .comment-form-comment textarea {
        resize: none;
        width: 100%;
        height: 166px;
        background-color: transparent;
        border: 1px #000 solid;
        padding: 12px 12px 8px;
        font-size: .9375rem;
        color: #000;
        font-family: "HelveticaNeueLTStd",Arial,Verdana;
        outline: 0;
        display: block;
        -webkit-appearance: none;
        appearance: none;
        -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 0;
        -ms-border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        overflow: auto;
        vertical-align: top;
        margin: 0; }
        #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:hover, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:focus, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:active {
          background-color: rgba(0, 0, 0, 0.02);
          border-color: #477824; }
    #reviews #review_form_wrapper .comment-form .input__nombre {
      width: 100%;
      margin: 0 !important; }
      #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author {
        width: 100%;
        margin-bottom: 0; }
        #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input {
          width: 100%;
          background-color: transparent;
          border: 1px #000 solid;
          padding: 3px 10px 3px;
          font-size: 0.873rem;
          color: #000;
          font-family: "HelveticaNeueLTStd",Arial,Verdana;
          outline: 0;
          display: block;
          -webkit-appearance: none;
          appearance: none;
          -o-box-sizing: border-box;
          -ms-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 0;
          -ms-border-radius: 0;
          -moz-border-radius: 0;
          -webkit-border-radius: 0;
          -khtml-border-radius: 0; }
          #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:hover, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:focus, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:active {
            background-color: rgba(0, 0, 0, 0.02);
            border-color: #477824; }
    #reviews #review_form_wrapper .comment-form .pprivacy {
      font-size: .875rem;
      line-height: 22px;
      margin-top: 10px; }
      #reviews #review_form_wrapper .comment-form .pprivacy .privacyBox {
        position: relative;
        margin-right: 10px;
        vertical-align: middle;
        width: 16px;
        height: 16px; }
      #reviews #review_form_wrapper .comment-form .pprivacy a {
        font-weight: 700;
        color: #000; }
    #reviews #review_form_wrapper .comment-form .form-submit {
      margin-top: 28px; }
      #reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro {
        height: 36px;
        text-transform: uppercase;
        background-color: #dfc08c;
        border: 0;
        border-radius: 0%;
        color: #fff;
        text-decoration: none;
        display: inline-block;
        transition: all .3s ease;
        text-align: center;
        line-height: normal;
        padding: 8px 18px 4px;
        font-family: "HelveticaNeueLTStd",Arial,Verdana;
        border: none; }
        #reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro:hover {
          background-color: #595959;
          color: #fff; }
  #reviews #review_form_wrapper .Legal-form {
    width: 100%;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 28px; }
    #reviews #review_form_wrapper .Legal-form p {
      margin: unset;
      letter-spacing: -1px;
      font-size: 12px; }
    #reviews #review_form_wrapper .Legal-form ul {
      padding: 0; }
      #reviews #review_form_wrapper .Legal-form ul li {
        list-style: none;
        font-size: .75rem;
        line-height: 22px;
      }
        #reviews #review_form_wrapper .Legal-form ul li a {
          color: #000;
          text-decoration: underline; }

/*# sourceMappingURL=ratings.css.map */

#comments li{
    padding-bottom: 20px;
    margin-bottom: 28px;
    border-bottom: 1px solid #979797;
    border: 0;
    vertical-align: baseline;
    list-style: none;
    list-style-image: none;
}

#comments li .meta {
    margin-bottom: 10px;
}

#comments li .meta strong{
    font-size: .875rem;
    font-weight: 400;
}

#comments li .meta time{
    float: right;
    font-size: 14px;
    font-size: .875rem;
    font-weight: 300;
}

#comments li p{
    line-height: 22px;
    font-size: 1.375rem;
    font-weight: 300;
    font-family: unset;
    margin-bottom: 10px;
}

#comments .star-rating{
    font-size: .75rem;
    margin-top: -10px;
}

#comments .star-rating i{
    margin-right: 5px;
}

#comments .description p{
    font-size: .875rem;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 24px;
}
@media screen and (min-width: 320px) and (max-width: 375px) {
  .item__row__custom.inverse__custom,
  .display_my_img_txt.display__img__text {
    display: flex;
    flex-direction: column-reverse; }

  .display_my_img_txt .text-cn > div {
    position: unset !important;
    text-align: left !important;
    margin-top: -40px;
    padding: unset !important; }

  .history:before {
    display: none; }
  .history .changeOrder {
    display: flex;
    flex-direction: column-reverse; }
  .history .type-txt {
    text-align: center !important; }
  .history .type-num {
    text-align: center !important;
    padding: 0px !important; }
  .history .c-r.type-num p {
    margin-bottom: 0; 
  }
}

/*# sourceMappingURL=responsive.css.map */
@media screen and (min-width: 320px) and (max-width: 375px) {
    .display_my_img_txt .c-l.text-cn p {
        text-align: center !important;
    }
}
@font-face {
  font-family: "Freeland";
  src: url(/dist/loreal-biolage-ita-jss/static/media/Freeland.a18f1fe8.woff) format("woff"); }
@font-face {
  font-family: "PopulaireLight";
  src: url("/dist/loreal-biolage-ita-jss/static/media/Populaire Light.dc2c2660.ttf") format("truetype"); }
@font-face {
  font-family: "Helvetica Bd";
  src: url(/dist/loreal-biolage-ita-jss/static/media/HelveticaNeueLTStd-Bd.03abbcc7.woff) format("woff"); }
@font-face {
  font-family: "Helvetica Lt";
  src: url(/dist/loreal-biolage-ita-jss/static/media/HelveticaNeueLTStd-Lt.b89aa149.woff) format("woff"); }
@font-face {
  font-family: "Helvetica Md";
  src: url(/dist/loreal-biolage-ita-jss/static/media/HelveticaNeueLTStd-Md.06e7da9a.woff) format("woff"); }
@font-face {
  font-family: "Helvetica Rom";
  src: url(/dist/loreal-biolage-ita-jss/static/media/HelveticaNeueLTStd-Roman.77b803c9.woff) format("woff"); }
@font-face {
  font-family: "Helvetica Std";
  src: url(/dist/loreal-biolage-ita-jss/static/media/HelveticaNeueLTStd-Roman.77b803c9.woff) format("woff"); }

.freeland {
  font-family: "Freeland", Arial, Verdana;
  text-decoration: none;
  font-size: 3.25rem;
}
.populaireLight{
  font-family: "PopulaireLight",Arial,Verdana !important;
}
.helv_bd{
  font-family: "Helvetica Bd", Arial, Verdana !important;
}
.helv_lt{
  font-family: "Helvetica Lt", Arial, Verdana !important;
}
.helv_md{
  font-family: "Helvetica Md", Arial, Verdana !important;
}
.helv_rom{
  font-family: "Helvetica Rom", Arial, Verdana !important;
}
.helv_std{
  font-family: "Helvetica Std", Arial, Verdana !important;
}
.montserrat{
  font-family: 'Montserrat', sans-serif;
  font-size: 15px !important;
}
.montserrat_semi_bd{
  font-family: 'Montserrat Semi-Bold', sans-serif;
}
.montserrat_bd{
  font-family: 'Montserrat Bold', sans-serif;
}

.montserrat{
  font-family: 'Montserrat', sans-serif;
  font-size: 15px !important;
}
.montserrat_semi_bd{
   font-family: 'Montserrat Semi-Bold', sans-serif;
 }
.montserrat_bd{
  font-family: 'Montserrat Bold', sans-serif;
}

* {
  font-family: "HelveticaNeueLTStd", Arial, Verdana; }
#main_custom .row_custom_small+.row_custom_small {
  margin-top: 20px;
}
a {
  text-decoration: none;
  transition: all .3s ease;
}

.img_custom_full{
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none;
  max-height: 100%;}

#main_custom .btn {
  text-decoration: none;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  padding: 10px 12px 8px;
  width: auto;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  font-size: 13px;
  color: #fff;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;
}

.btn_custom{
  height: 45px;
  line-height: 36px;
  font-size: 1.375rem;
  text-transform: uppercase;
  background-color: #dfc08c;
  border: 1px solid #dfc08c;
  padding: 8px 18px 4px;
  color: #fff;
}

.btn_oro{
  background-color: #dfc08c;
  color: #fff;
}

[class*=col-] {
  float: left;
  width: 100%;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

.row_custom_small {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1100px;
  margin-left: auto !important;
  margin-right: auto !important; }

.Separa-cn {
  margin: 20px 0 40px;
  text-align: center;
}

blockquote{
  max-width: 990px;
  font-size: 1.375rem;
  line-height: 30px;
  margin: 24px auto 14px;
  margin-bottom: 40px;
  quotes: none;
}

header{
  z-index: 999;
}

#menu1 {
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: -11px; }
#menu1 .container {
  padding-right: 45px;
  padding-left: 45px; }
#menu1 .container .navbar-nav .nav-item {
  font-size: .75rem;
  margin-left: 40px; }
#menu1 .container .navbar-nav .nav-item a {
  font-size: .75rem;
  color: #000 !important;
  font: normal normal normal 14px/1 FontAwesome; }

#menu2 {
  background-color: #fff; }
#menu2 .navbar-nav .nav-item {
  padding: 22px 30px 17px; }
#menu2 .navbar-nav .nav-item:hover {
  background-color: #f0f0f0; }
#menu2 .navbar-nav .nav-item .nav-link {
  font-size: .875rem;
  color: #000 !important;
  text-transform: uppercase; }
#menu2 .navbar-nav .nav-item .nav-link:hover {
  color: #9fba5d; }
#menu2 .navbar-nav .nav-item .menu-hd {
  margin-left: 28px; }

#main_custom .carousel-indicators {
  bottom: -60px; }
#main_custom .carousel-indicators li {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #c0bfbf; }
#main_custom .carousel-indicators li.active {
  background-color: #9fba5d; }
#main_custom .section2 .pad,
#main_custom .section3 .pad,
#main_custom .section4 .pad {
  padding: 20px;
  margin-top: 15px;}
#main_custom .section2 .pad h1,
#main_custom .section3 .pad h1,
#main_custom .section4 .pad h1 {
  font-size: 2.125rem;
  font-family: "Freeland",Arial,Verdana;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
}
#main_custom .section2 h2{
  padding: 1% 0;
  font-size: 18px;
}
.nextSec2{
  margin-top: -8px !important;
}
#main_custom .section2 .portfolio-caption,
#main_custom .section3 .portfolio-caption,
#main_custom .section4 .portfolio-caption {
  margin: 1em;
  height: 130px;
  line-height: 26px;
}
#main_custom .section2 .portfolio-caption h2,
#main_custom .section3 .portfolio-caption h2,
#main_custom .section4 .portfolio-caption h2 {
  font-size: 1.5625rem;
  margin: 10px 0 4px;
  font-weight: 500; }
#main_custom .portfolio-item:hover,
.home__img_marg img:hover{
  opacity: .8;
  cursor: pointer;
}
.portfolio-item .far.off{
  margin: 0 2px 15px;
}
.home__img_marg.no__hover img:hover{
  opacity: unset;
  cursor: unset;
}
#main_custom .section2 .portfolio-caption p,
#main_custom .section3 .portfolio-caption p,
#main_custom .section4 .portfolio-caption p {
  font-size: 1.25rem;
  color: #000;
  line-height: 22px;
}
#main_custom .section2 .line_btn,
#main_custom .section3 .line_btn,
#main_custom .section4 .line_btn {
  margin-left: 100%;
  margin-right: 100%;
  margin-top: 34px; }
#main_custom .section2 .line_btn .btn_custom,
#main_custom .section3 .line_btn .btn_custom,
#main_custom .section4 .line_btn .btn_custom {
  height: 45px;
  text-transform: uppercase;
  padding: 8px 40px 4px;
  color: #fff;
  margin-top: -59px;
  width: auto;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 28px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;
}
.btn_custom:focus,
.btn_custom:hover {
  background-color: #595959;
  color: #fff;
}
#main_custom .section2 .min_font h2,
#main_custom .section3 .min_font h2,
#main_custom .section4 .min_font h2 {
  font-size: 1rem;
  font-weight: 300;
  min-height: 44px; }
#main_custom .sep {
  margin: 5% auto; }

.history {
  position: relative; }
.history:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 4px;
  margin: 0 auto;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAADTBAMAAAC2OLSjAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABHRSTlMANVSskIQ2cgAAAB1JREFUKM9jMDZgcHHAiZQUGPCAUb2jesnTKygAAPzcOgPioxbJAAAAAElFTkSuQmCC);
  background-position: bottom center;
  background-repeat-x: no-repeat; }
.history [class*=col-] {
  float: left;
  width: 100%;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }
.history .row_custom_small {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.history .row_custom_small:before {
  content: " ";
  display: table; }
.history .row_custom_small:after {
  display: block;
  clear: both;
  content: ''; }
.history .c-r {
  float: right; }
.history .c-l.type-txt {
  text-align: right; }
.history .c-l.type-num {
  text-align: right;
  padding-right: 51px; }
.history .type-txt h2 {
  font-size: 2.625rem;
  line-height: 42px;
  font-family: "Freeland",Arial,Verdana;
  margin-bottom: 16px; }
.history .type-txt p {
  font-size: 1rem;
  line-height: 30px;
  font-weight: 300;
  font-family: "HelveticaNeueLTStd",Arial,Verdana; }
.history .type-txt > div {
  width: 310px;
  display: inline-block;
  width: 100%;}
.history .type-num {
  padding-left: 54px;
  padding-top: 24px;
  padding-bottom: 20px;
  color: #9fba5d; }
.history .type-num p {
  padding-top: 20px;
  font-size: 2.625rem;
  background-color: #fff;
  display: inline-block;
  text-align: center;
  font-family: "Freeland",Arial,Verdana; }

.header_maps {
  padding: 80px 0;
  background-image: url(/dist/loreal-biolage-ita-jss/static/media/hero-salon.de78b2f2.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
.header_maps .maps_salons {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  margin: auto;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }
.header_maps .maps_salons h1 {
  font-family: "Freeland",Arial,Verdana;
  font-size: 2.625rem;
  margin: 50px 0 40px; }
.header_maps .maps_salons .bar-cn > * {
  margin: 0 10px 20px;
  vertical-align: middle; }
.header_maps .maps_salons .bar-cn .btn_custom_oro {
  width: 240px;
  vertical-align: middle;
  font-size: 1.125rem;
  font-weight: 300;
  height: 42px;
  text-transform: uppercase;
  background-color: #dfc08c;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border: none;
  border-radius: 0%; }
.header_maps .maps_salons .bar-cn .form_custom_maps {
  display: inline-block; }
.header_maps .maps_salons .bar-cn .form_custom_maps input {
  display: inline-block;
  margin-right: -5px;
  background-color: transparent;
  border: 1px #000 solid;
  padding: 10px 12px 8px;
  font-size: .9375rem;
  color: #000;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  outline: 0;
  -webkit-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0; }
.header_maps .maps_salons .bar-cn .form_custom_maps button {
  font-size: 1.125rem;
  font-weight: 300;
  height: 42px;
  text-transform: uppercase;
  background-color: #dfc08c;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border-radius: 0;
  margin: -4px 0 0 0; }

.link_social a {
  transition: all .3s ease; }
.link_social a:hover {
  text-decoration: none;
  opacity: .6; }

.link_share a {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 48px;
  text-align: center;
  vertical-align: middle;
  border: 2px solid #000;
  color: #000;
  font-size: 1.5rem;
  margin-right: 20px;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%; }
.link_share a:hover {
  color: #9fba5d;
  border-color: #9fba5d; }
.link_share a:hover i {
  color: #9fba5d; }
.link_share a i {
  padding-top: 8px; }

.row_custom__richtext {
  display: flex;
  flex-wrap: wrap;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.row_custom__richtext p {
  font-size: .9375rem;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 300; }
.row_custom__richtext .padding_custom {
  padding: 20px; }
.row_custom__richtext .padding_custom h1 {
  margin: 34px 0 20px;
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 42px;
  font-weight: 700; }
.row_custom__richtext .custom_h1,
.row_custom__richtext .custom_title {
  color: #9fba5d;
  margin: 0 0 20px;
  font-size: 2.25rem;
  text-transform: uppercase;
  line-height: 42px; }
.row_custom__richtext .custom_h1 strong,
.row_custom__richtext .custom_title strong {
  border-bottom: 3px solid #9fba5d;
  font-weight: 700; }
.row_custom__richtext .last_margin {
  margin: 0 0 40px; }
.row_custom__richtext .custom_title {
  margin: 22px 0 22px;
  line-height: 50px; }

/*.related__product__custom {*/
/*  margin-top: 6%; }*/
.related__product__custom .pad {
  padding: 20px; }
.related__product__custom .pad h1 {
  font-size: 2.625rem;
  font-family: "Freeland",Arial,Verdana;
  text-align: center;
  /*font-weight: bold;*/
}
.related__product__custom .portfolio-caption{
  margin-bottom: 1em;
  height: auto; }
.related__product__custom .portfolio-caption h2{
  line-height: 23px;
  display: block;
  font-size: 15px;
  font-weight: 300;
  height: auto;
  margin-bottom: 0;
  padding: 0 42px;}
.related__product__custom .portfolio-caption p {
  font-size: 1.25rem;
  margin-top: 2px;
}
.related__product__custom .line_btn {
  margin-left: 100%;
  margin-right: 100%;
  margin-top: 34px; }
.related__product__custom .btn_custom_oro {
  text-transform: uppercase;
  font-size: 1rem;
  height: 40px;
  line-height: 44px;
  padding: 0 10px;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff;
  border-radius: 0;
  width: 65% !important;
  margin-top: 5%;
  z-index: 10;
  position: relative;
}
.related__product__custom .btn_custom_oro:hover {
  background-color: #595959;
  color: #fff; }
.related__product__custom .prod__item:focus,
.related__product__custom .prod__item:hover {
  box-shadow: 0 2px 10px rgba(198,198,198,.5);
}
.related__product__custom .portfolio-link:hover {
  cursor: pointer; }
.related__product__custom .prod__item{
  position: relative;
  margin-bottom: 26px;
}
.related__product__custom .portfolio-link{
  display: block;
  text-align: center;
  color: #000;
  padding-bottom: 16px;
  margin-bottom: -48px;
  height: 382px;
}
.related__product__custom .ratedCustom h1{
  margin-bottom: -12px
}
.related__product__custom .customWidth{
  width: 42% !important;
}
.related__product__custom .portfolio-item{
  opacity: initial !important;
}
.related__product__custom .portfolio-linkCustom .img-fluid{
  height: 215px !important;
  width: 215px !important;
}
.custom_margin{
  margin-bottom: 10px;
}
.related__product__custom .prod__item i{
  font-size: .875rem;
  margin: 0 4px 20px;
  color: #000;
}
.related__product__custom i.fa.fa-star.off:before {
  color: #d6d6d6;
  font: normal normal normal 14px/1 FontAwesome;
}
.fa.fa-star,
.fa.fa-star.off,
.fa.fa-star-half-o.off{
  font-size: .855rem;
  margin: 0 2px 15px;
}
.fa-star.off:before {
  color: #d6d6d6;
  font: normal normal normal 14px/1 FontAwesome;
}
.fa{
  margin: 0 2px 15px;
  font: normal normal normal 14px/1 FontAwesome;
}
.comment-text .fa{
  margin: unset;
  margin-right: 5px;
}
.ingre_modal .close{
  position: initial;
}
.title-review {
  text-align: center;
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland",Arial,Verdana;
  width: 100%; }

.same__font {
  font-size: .875rem; }
.same__font i:before{
  font-family: 'FontAwesome';
  color: #d6d6d6;
  transition: all .3s ease;
}
.another__font {
  font-size: 1.375rem; }

#reviews {
  margin-top: 34px; }
#reviews p {
  margin-bottom: 10px; }
#reviews #comments .Reviews-title {
  display: none; }
#reviews #comments .noreviews {
  line-height: 30px; }
#reviews #review_form_wrapper .comment-reply-title {
  line-height: 22px; }
#reviews #review_form_wrapper .comment-form {
  line-height: 30px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating {
  line-height: 22px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars {
  display: inline-block;
  margin-left: 8px;
  line-height: 30px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a {
  padding: 0 4px; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:hover {
  text-decoration: none; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:focus > i, #reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:active > i {
  color: #000; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a:before {
  transition: all .3s ease; }
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a i:before {
  font-family: 'FontAwesome';
  color: #000;
  transition: all .3s ease;
}
#reviews #review_form_wrapper .comment-form .comment-form-rating .stars a i.off:before{
  color: #d6d6d6;
}
.min_font .fa-star:before{
  margin: unset;
}
#reviews #review_form_wrapper .comment-form .comment-form-comment {
  line-height: 30px; }
#reviews #review_form_wrapper .comment-form .comment-form-comment textarea {
  resize: none;
  width: 100%;
  height: 166px;
  background-color: transparent;
  border: 1px #000 solid;
  padding: 12px 12px 8px;
  font-size: .9375rem;
  color: #000;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  outline: 0;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  overflow: auto;
  vertical-align: top;
  margin: 0; }
#reviews #review_form_wrapper .comment-form .comment-form-comment textarea:hover, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:focus, #reviews #review_form_wrapper .comment-form .comment-form-comment textarea:active {
  background-color: rgba(0, 0, 0, 0.02);
  border-color: #477824; }
#reviews #review_form_wrapper .comment-form .input__nombre {
  width: 100%;
  margin: 0 !important; }
#reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author {
  width: 100%;
  margin-bottom: 0; }
#reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input {
  width: 100%;
  background-color: transparent;
  border: 1px #000 solid;
  padding: 3px 10px 3px;
  font-size: 0.873rem;
  color: #000;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  outline: 0;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0; }
#reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:hover, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:focus, #reviews #review_form_wrapper .comment-form .input__nombre .comment-form-author input:active {
  background-color: rgba(0, 0, 0, 0.02);
  border-color: #477824; }
#reviews #review_form_wrapper .comment-form .pprivacy {
  font-size: .875rem;
  line-height: 22px;
  margin-top: 10px; }
#reviews #review_form_wrapper .comment-form .pprivacy .privacyBox {
  position: relative;
  margin-right: 10px;
  margin-top: 3px;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  float: left; }
#reviews #review_form_wrapper .comment-form .pprivacy a {
  font-weight: 700;
  color: #000; }
#reviews #review_form_wrapper .comment-form .form-submit {
  margin-top: 28px; }
#reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro {
  height: 36px;
  text-transform: uppercase;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;}
#reviews #review_form_wrapper .comment-form .form-submit .btn_custom_oro:hover,
.btn_custom:hover{
  background-color: #595959 !important;
  color: #fff !important; }
#reviews #review_form_wrapper .Legal-form {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 28px; }
#reviews #review_form_wrapper .Legal-form p {
  margin: unset;
  font-size: 12px;
  letter-spacing: unset; }
#reviews #review_form_wrapper .Legal-form ul {
  padding: 0; }
#reviews #review_form_wrapper .Legal-form ul li {
  list-style: none;
  font-size: .75rem;
  line-height: 22px;
  font-family: "Helvetica Lt", Arial, Verdana;
}
#reviews #review_form_wrapper .Legal-form ul li a {
  color: #000;
  text-decoration: underline; }

.list__product__item {
  display: block;
  text-align: center;
  margin-top: 20px;
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.list__product__item img {
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }
.list__product__item .star-cn {
  margin-top: 1rem;
  height: 24px;}
.list__product__item .off,
.prod__item .off{
  color: #d6d6d6 !important;
  font-size: .875rem; }
.list__product__item .colo__star {
  color: #000; }
.list__product__item .Producto-item {
  float: none;
  margin: 0 -2px;
  display: inline-block;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  height: 450px !important; }
.list__product__item .Producto-item button {
  overflow: visible;
  padding: 0;
  background: 0 0;
  border: 0; }
.list__product__item .Producto-item .plus__custom{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 22px;
  height: 25px;
  transition: all .3s ease;
  cursor: pointer; }
.list__product__item .link__custom__item {
  display: block;
  text-align: center;
  color: #000;
  padding-bottom: 16px; }
.list__product__item .link__custom__item:hover {
  text-decoration: none;
  box-shadow: 0 2px 10px rgba(198, 198, 198, 0.5); }
.list__product__item .link__custom__item .title-cn {
  font-size: 1rem;
  min-height: 48px;
  margin-bottom: 10px;
  font-weight: 300;
  display: block;
}
.list__product__item .link__custom__item .btn_custom_oro {
  height: 40px;
  line-height: 46px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #dfc08c;
  border: 0;
  border-radius: 0%;
  color: #fff;
  padding: 0 18px;
  font-weight: 300; }
.list__product__item .link__custom__item .btn_custom_oro:hover {
  background-color: #595959;
  color: #fff; }
.list__product__item .Producto-item .far.off{
  margin: 0 2px 15px;
}

.modal-xl {
  max-width: 900px !important; }
.modal-xl .modal-content {
  padding: 8px 5px 20px; }
.modal-xl .modal-content .star-cn {
  margin-top: 0; }
.modal-xl .modal-content .modal-header {
  padding-bottom: 0;
  margin-bottom: -20px;
  border: none !important; }
.modal-xl .modal-content .modal-header .close {
  z-index: 2050;
  background-color: #fff !important;
  width: 47px;
  height: 47px;
  line-height: 47px;
  color: #000;
  font-size: 2.625rem;
  text-align: center;
  padding: 0;
  top: 6px;
  right: 6px; }
.modal-xl .modal-content img {
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }
.modal-xl .modal-content .popup-title_item {
  font-size: 2.25rem;
  margin-top: 10px;
  font-weight: 700;
  margin-bottom: 10px; }
.modal-xl .modal-content .litro__custom {
  font-size: 1.0625rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 18px;
  display: block; }
.modal-xl .modal-content .pop__description {
  font-size: .875rem;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 30px; }
.modal-xl .modal-content p {
  font-size: .9375rem;
  line-height: 24px;
  font-weight: 400; }
.modal-xl .modal-content .btn_custom_oro {
  height: 40px;
  line-height: 46px;
  font-size: 1rem;
  text-transform: uppercase;
  background-color: #dfc08c;
  border: 0;
  border-radius: 0%;
  color: #fff;
  padding: 0 18px;
  font-weight: 300; }
.modal-xl .modal-content .btn_custom_oro:hover {
  background-color: #595959 !important;
  color: #fff !important; }
.modal .modal-content .close{
  z-index: 2;
}
.custom_list h1 {
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland", Arial, Verdana; }
.custom_list{
  background: #dfc08c42;
}
.custom_list .list_ingredients > * {
  float: none;
  margin: 0 -2px; }
.custom_list .list_ingredients{
  display: inline-flex;
}
.custom_list .list_ingredients .desc{
  margin: 16px 87px !important;
}
.custom_list .list_ingredients .col-md-4 {
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  display: inline-block;
  font-family: "HelveticaNeueLTStd",Arial,Verdana; }
.custom_list .list_ingredients .col-md-4 img {
  margin: 0 35px 20px; }
.custom_list .list_ingredients .col-md-4 .name_ing_custom {
  font-weight: 700; }
.custom_list .list_ingredients .col-md-4 p {
  margin-top: 6px; }
/*.custom_list .btn_div_custom {*/
/*  margin: 40px 0; }*/
.custom_list .btn_div_custom .btn {
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border-radius: 0; }
.custom_list .btn_div_custom a {
  font-size: 1.375rem;
  text-transform: uppercase;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff;
}
.custom_list .btn_div_custom a:hover {
  background-color: #595959; }

.list_cn {
  padding-left: 15px;
  padding-right: 15px; }
.list_cn .link_ingredient {
  padding: 20px;
  padding-left: 5px;
  padding-right: 5px;
  color: #000;
  transition: all .3s ease; }
.list_cn .link_ingredient:hover {
  text-decoration: none; }
.list_cn .link_ingredient:focus >div,
.list_cn .link_ingredient:hover>div {
  background-color: #d0d3c2;
}
.list_cn .link_ingredient > div {
  min-height: 355px;
  background-color: #dee0d4;
  padding: 12px;
  transition: all .3s ease; }
.list_cn .link_ingredient > div .img-cn {
  text-align: center;
  display: block;
  background-color: #fff; }
.list_cn .link_ingredient > div .img-cn img {
  max-height: 242px;
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }
.list_cn .link_ingredient .title-cn {
  text-align: center;
  font-size: 1.675rem;
  font-family: Freeland,Arial,Verdana;
  display: block;
  margin: 32px 0 0;
  font-weight: 500; }
.list_cn .modal-xl {
  max-width: 1055px; }
.list_cn .modal-xl .modal-content .modal-header {
  border-bottom: unset;
  padding-bottom: 0;
  margin-bottom: -30px; }
.list_cn .modal-xl .modal-content .modal-header .close {
  z-index: 2050; }
.list_cn .modal-xl .modal-content .popup-title {
  font-size: 1.375rem;
  margin-top: 50px;
  padding-right: 40px;
  font-weight: 700;
  margin-bottom: 10px; }
.list_cn .modal-xl .modal-content p {
  font-size: .9375rem;
  line-height: 24px;
  font-weight: 400; }


@media (min-width: 350px) {
  .slicknav_menu {
    top: 46px; } }
@media (min-width: 1005px) {
  .slicknav_menu {
    display: none !important; } }
.all_category_product h1 {
  padding-left: 20px;
  font-size: 1.125rem;
  margin-bottom: 20px; }
.all_category_product .row_custom_small {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto; }
.all_category_product .row_custom_small .col-md-12,
.all_category_product .row_custom_small .col-md-6 {
  float: left;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }
.all_category_product .row_custom_small .col-md-12 h1,
.all_category_product .row_custom_small .col-md-6 h1 {
  color: #9fba5d;
  font-size: 4.6875rem;
  text-transform: uppercase;
  font-family: "PopulaireLight",Arial,Verdana;
  line-height: 54px;
  margin-bottom: unset; }
.all_category_product .list_cat_product__custom {
  margin-top: 20px; }
.all_category_product .list_cat_product__custom .item_product_category {
  color: #000;
  text-align: center;
  height: 530px;}
.all_category_product .list_cat_product__custom .item_product_category:hover {
  opacity: .8;
  text-decoration: none; }
.all_category_product .list_cat_product__custom .item_product_category img {
  display: block;
  margin: 0 auto 10px;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  outline: none;
  height: 370px !important;
  width: 370px;
  height: 370px !important;
}
.all_category_product .list_cat_product__custom .item_product_category h2 {
  font-size: 1.375rem;
  font-weight: 300;
  margin-bottom: unset; }
.all_category_product .list_cat_product__custom .item_product_category p {
  font-size: 1.125rem;
  line-height: 30px;
  margin-bottom: 18px;
  color: #000;
  text-align: center;
  font-weight: 300; }
.all_category_product .list_cat_product__custom .item_product_category .btn-oro {
  text-transform: uppercase;
  font-size: 1.25rem;
  background-color: #dfc08c;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  border: none;
  border-radius: unset;
  overflow: visible; }
.all_category_product .list_cat_product__custom .item_product_category .btn-oro:hover {
  background-color: #595959;
  color: #fff; }
.all_category_product .Separa-cn {
  margin: 20px 0 40px;
  text-align: center; }

.breadcrumb__custom {
  font-size: .875rem;
  line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 700;
  text-align: left;
  overflow: auto;}
.breadcrumb__custom a {
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  color: #000;
  font-weight: 400; }
.breadcrumb__custom a:hover {
  color: #477824;
  text-decoration: none; }

.item__row__custom {
  position: relative;
  max-width: 822px;
  margin: 0 auto; }
.item__row__custom .c-r {
  float: right;
  padding: 10px; }
.item__row__custom .c-r .text-cn > div {
  background-color: #fff; }
.item__row__custom .text-cn {
  top: 0;
  bottom: 0; }
.item__row__custom .text-cn > div {
  /*position: absolute;*/
  height: 100%;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center; }
.item__row__custom .text-cn > div > div {
  padding: 20px; }
.item__row__custom .c-l,
.item__row__custom .c-r {
  padding: 10px; }
.item__row__custom .c-l.text-cn {
  text-align: right; }
.item__row__custom .c-l.text-cn > div {
  background-color: #f8e5ca; }
.item__row__custom h1 {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 2.625rem;
  font-family: "Freeland",Arial,Verdana;
  line-height: 40px; }
.item__row__custom p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 300;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  color: #000; }
.item__row__custom img {
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  outline: none;
  height: 100% !important;}

.title__custom__articles {
  font-size: 4rem;
  font-family: "PopulaireLight",Arial,Verdana; }

.custom__title_article h1{
  font-size: 3.375rem;
  font-weight: 300;
  margin: 32px 0;
  text-transform: uppercase;
  color: #9fba5d;
  font-family: "PopulaireLight",Arial,Verdana;
}
.dateCustom{
  margin: -10% 0 14% 0;
  font-size: 1.125rem;
  font-family: 'HelveticaNeueLTStd', Arial, Verdana;
  font-size: 18px;
}
.cat__custom__article ul {
  padding-left: 0;
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  border: 0;
  vertical-align: baseline;
  list-style: none; }
.cat__custom__article ul li {
  width: 24%;
  margin-bottom: 10px; }
.cat__custom__article ul li a {
  justify-content: center;
  width: 100% !important;
  height: 150px;
  color: #000 !important;
  font-size: .875rem !important;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: unset;
  border: none !important;
  border-radius: unset !important;
}
.cat__custom__article ul li a span {
  display: block;
  width: 176px;
  height: 26px;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.86);
  margin: 22% 0 22% 25%; }
.cat__custom__article ul li a span {
  width: 116px;
  height: 32px;
  line-height: 33px;
  margin: 50% 0 50% 56%;
}
.cat__custom__article ul li a span {
  display: block;
  width: 176px;
  height: 26px;
  line-height: 28px;
  background-color: rgba(255, 255, 255, 0.86);
  margin: 55px auto;
  }

.all-articles__custom {
  padding-left: unset !important;
  padding-right: unset !important; }
.all-articles__custom:first-child{
  padding-top: 0px !important;
}
.all-articles__custom .item__article__only {
  height: auto;
  position: relative;
  color: #000;
  padding-bottom: 80px;
  margin-bottom: 30px; }
.all-articles__custom .item__article__only:hover {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  text-decoration: none; }
.all-articles__custom .item__article__only:hover button{
  background-color: #595959;
  color: #fff;
}
.all-articles__custom .item__article__only:before {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: 0;
  height: 4px;
  background-color: rgba(155, 155, 155, 0.2);
  transition: all .3s ease; }
.all-articles__custom .item__article__only:hover:before {
  width: 75%; }
.all-articles__custom .item__article__only img {
  display: block;
  margin: 0 auto; }
.all-articles__custom .item__article__only h1 {
  display: block;
  min-height: 90px;
  font-size: 1.5625rem;
  margin: 25px 0 20px; }
.all-articles__custom .item__article__only p {
  font-size: 1rem;
  min-height: 76px;
  font-weight: 300;
  margin-bottom: 16px;
}
.all-articles__custom .item__article__only button {
  font-size: 1.375rem;
  /* position: absolute;
  bottom: 20px;
  left: 20px; */
  text-transform: uppercase;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff;
  border-radius: 0%;
  /* margin: 0% 18%; */
}

.pagenavi {
  font-size: 1.125rem; }
.pagenavi span,
.pagenavi a {
  position: relative;
  padding: 5px 5px 4px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  background-color: #dfc08c;
  margin: 0 1px;
  min-width: 26px;
  height: 29px;
  font-size: .875rem; }
.pagenavi span:hover,
.pagenavi a:hover {
  text-decoration: none;
  color: #fff;
  cursor: pointer;}
.pagenavi a.nextpostslink {
  margin-right: 0; }
.pagenavi span.current {
  font-weight: 600;
}

.text_notfound p {
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  font-weight: 500; }
.text_notfound p:first-child {
  margin: -6px 0; }

.display_my_img_txt {
  position: relative; }
.display_my_img_txt .c-r {
  float: right; }
.display_my_img_txt .c-r {
  float: right; }
.display_my_img_txt .c-l.img-cn {
  text-align: right; }
.display_my_img_txt .c-r.text-cn {
  right: 0; }
.display_my_img_txt .c-r.text-cn p {
  text-align: left; }
.display_my_img_txt .c-l.text-cn p {
  text-align: right; }
.display_my_img_txt .text-cn {
  top: 0;
  bottom: 0; }
.display_my_img_txt .text-cn > div {
  position: absolute;
  bottom: 0;
  text-align: right;
  left: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }
.display_my_img_txt .text-cn p {
  max-width: 538px;
  display: inline-block;
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 30px;
  font-weight: 300; }
.display_my_img_txt .text-cn p img {
  width: 538px; }
.display_my_img_txt .c-l,
.display_my_img_txt .c-r {
  padding: 20px; }
.display_my_img_txt .c-l.text-cn {
  text-align: right; }
.display_my_img_txt p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 24px;
  font-weight: 300;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  color: #000; }
.display_my_img_txt img {
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }

/*# sourceMappingURL=styles.css.map */
.up_index{
  z-index: 999;
}
.wrapper {
  display: block;
  margin: 16px 0 40px;
}
.custom__height{
  height: 70px;
  text-align: center;
}
.main-hd .menu-hd>li>.sub-menu>li.col__5:nth-child(2) li {
  width: 50%;
  display: inline-block;
  margin: 0 -2px;
  padding: 0 10px 0 0;
}
.home__img_marg{
  margin: 40px 0 20px;
  text-align: center;
}

/* Search */
.search__custom{
  margin-bottom: 160px;
  height: 447px;
}
.search__custom .row_custom_small{
  padding: 80px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.search__custom .col__custom{
  text-align: center;
  background-color: rgba(255,255,255,.9);
}
.search__custom .col__custom:last-child{
  margin: auto!important;
}
.search__custom h1{
  font-size: 2.625rem;
  margin: 50px 0 40px;
  font-family: "Freeland",Arial,Verdana;
}
.search__custom form{
  margin-bottom: 50px;
  text-align: center;
}
.search__custom form input{
  display: inline-block !important;
  margin-right: 22px;
}
.search__custom input[type=email],
.search__custom input[type=password],
.search__custom input[type=tel],
.search__custom input[type=text],
select, textarea {
  background-color: transparent;
  border: 1px #000 solid;
  padding: 12px 12px 8px;
  font-size: .9375rem;
  color: #000;
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  outline: 0;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
}
.search__custom form button {
  cursor: pointer;
  font-size: 1.25rem;
  transition: all .3s ease;
  overflow: visible;
  padding: 0;
  background: 0 0;
  border: 0;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
}

.video__pad{
  padding: 0 20px 13px !important;
  height: 530px;
}

.video__pad .embed-responsive{
  height: 100%;
}

.custom_row_block{
  position: relative;
  max-width: 822px;
  margin: 0 auto;
}
.custom_row_block .row_custom_small+.row_custom_small{
  margin-top: unset;
}

.row-article-layout .cat__custom__article ul {
  display: block;
}

.row-article-layout .cat__custom__article ul li {
  width: 100%;
}

.row-article-layout .cat__custom__article ul li a span {
  margin: 20% 0 20% 18%;
}

.featured_posts_custom_heading h1{
  font-size: 4.6875rem;
  color: #9fba5d;
  text-align: center;
  font-family: "PopulaireLight",Arial,Verdana;
  text-transform: uppercase;
  margin-bottom: 26px;
}
.featured_posts_custom_heading blockquote{
  max-width: 990px;
  margin-bottom: 40px;
  font-size: 1.375rem;
  quotes: none;
  margin: 24px auto 14px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
  quotes: none;
}
.featured_posts_custom_heading p{
  margin-bottom: unset;
}
.featured_posts_custom{
  text-align: center;
  padding-bottom: 40px;
}
.featured_posts_custom h1{
  font-size: 2.625rem !important;
  font-family: "Freeland",Arial,Verdana;
  margin-top: 10px !important;
}
.featured_posts_custom h2{
  font-size: 1.4375rem;
  line-height: 30px;
  font-weight: 300;
  margin: 0 0 16px;
  min-height: 60px;
}
.featured_posts_custom p{
  font-size: 1rem;
  line-height: 30px;
  min-height: 150px;
  font-weight: 300;
  margin: 12px 0 24px;
}
.featured_posts_custom a{
  text-transform: uppercase;
  width: 161px;
  height: 31px;
  font-size: 0.875rem;
}
.featured_posts_custom .btn{
  padding: 7px 10px 8px !important;
}
.featured_posts_cust{
  width: 320px !important;
  height: 320px !important;
}
.richtext_custom{
  font-size: .9375rem;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 300;
}
.cat__custom__article a:focus:before, .cat__custom__article a:hover:before {
  background-color: rgba(71,120,36,.9);
}
.product-categories li a{
  font-family: "Helvetica Lt", Arial, Verdana;
}
.category__summary__custom h1{
  font-size: 4.6875rem;
  font-family: "PopulaireLight",Arial,Verdana !important;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.category__summary__custom p{
  font-family: "Helvetica Lt", Arial, Verdana;
  font-size: 1.4375rem;
  line-height: 30px;
  font-weight: 300;
  max-width: 900px;
  margin: 0 auto;
}
.newRT{
  font-size: .9375rem;
  line-height: 24px;
  margin-bottom: 24px;
  font-weight: 300;
}
.cat__custom__article a:hover{
  background-color: rgba(71,120,36,.9);
}
time{
  color: #bebebe;
  display: block;
  margin: 12px 0 -28px;
  font-family: "Helvetica Md", Arial, Verdana !important;
  font-size: 16px;
}
time.time_article {
  display: inline-block;
}
.all-articles__custom button{
  font-family: "Helvetica Lt", Arial, Verdana !important;
}
.all-articles__custom .item__article__only h1{
  font-family: "Helvetica Std", Arial, Verdana !important;
}
.title__custom__articles small{
  font-size: 3rem;
  font-family: "PopulaireLight", Arial, Verdana;
}
.modal_popin_up{
  overflow: scroll;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  background: #000000ad;
}
.modal_popin_up .modal{
  height: auto !important;
}
.modal_popin_up .modal-header{
  border-bottom: 0px !important;
  padding: 0px !important;
}
.modal_popin_up .modal-header .close{
  text-shadow: unset !important;
  opacity: 1;
}

.modal_popin_up .modal .modal-content .close:hover{
  color: #fff !important;
}
.modal_popin_up{
  overflow: unset;
}
.no__hover .home__img_marg img:hover{
  cursor: initial !important;
}
.header__article__cus{
  font-size: 3.375rem;
  font-weight: 300;
  margin: 32px 0;
  text-transform: uppercase;
  color: #9fba5d;
}
.header__article__cus h1{
  font-size: 1.125rem;
  font-weight: 700;
}
.modal_popin_up .modal-content .close span {
  position: absolute;
  right: -135%;
  top: -8%;
  font-size: 3rem;
  z-index: 2;
  color: #f0f0f0;
}
.backmodal{
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  background: rgba(0,0,0,.6);
}
.text_notfound p {
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  font-weight: 500; }
.text_notfound p:first-child {
  margin: -6px 0; }

.list_cn .modal.fade.show{
  z-index: 2;
  background: #ffffffd4;
}
.modal-backdrop.fade.show{
  display: none;
}

.carousel .slider-wrapper,
.carousel .slider-wrapper.axis-horizontal .slider,
.carousel .slider.animated li div,
.carousel .slider.animated li div img,
.carousel .thumb.selected img
{
  height: auto !important;
}

.thumbs-wrapper ul{-webkit-padding-start: unset; padding-inline-start: unset;}
.thumbs-wrapper.axis-vertical{padding-top: 5px;}
/* Root on load display */
#root{
  opacity: 0;
  transition: all .5s ease-in-out;
}

.is-loaded {
  opacity: 1 !important;
}


a.portfolio-link, a.portfolio-link:hover  {
  text-decoration: none;
  color: unset;
}
/*<div className={'rounded_rated_prod'}></div>*/
/*.rounded_rated_prod{*/
/*  width: 235px;*/
/*  height: 235px;*/
/*  background-color: #b1b1b11f;*/
/*  border-radius: 50%;*/
/*  z-index: 2;*/
/*  position: absolute;*/
/*}*/
/* CLEAN CSS */
.sweet-loading{
  margin-top: 50px;
  margin-bottom: 50px;
}
.modal .modal-content.modal-content-newsletter {
  width: 500px;
  height: 780px;
}
.main-ft{
  background-color: rgba(155,155,155,.2);
  padding: 58px 0 30px;
}

.main-ft .row_custom_small:after,
.main-ft .row_custom_small:before {
  content: " ";
  display: table;
}

.main-ft .top-ft{
  height: auto;
}

.main-ft .logo {
  width: 278px;
  height: 30px;
}

.link_social>* {
  margin-right: 6px;
}
.main-ft .link_social {
  margin-top: -10px;
}

.link_stext {
  font-size: 2.125rem;
  font-family: "Freeland",Arial,Verdana;
  display: block;
  margin: 36px 0 26px;
  font-weight: 500;
}

.link_contact {
  margin-top: 6px;
}

.link_contact a {
  display: inline-block;
  margin-bottom: 6px;
  color: #000;
  font-size: .9375rem;
  font-weight: 300;
}

.link_contact a i {
  color: #949494;
  margin-right: 6px;
}

.main-ft .bottom-ft{
  height: 58px;
}

.main-ft .bottom-ft p {
  text-align: left;
  font-size: .9375rem;
}

.main-ft .bottom-ft a,
.main-ft .bottom-ft span {
  display: inline-block;
  margin: 0 12px;
}

.main-ft .bottom-ft a {
  position: relative;
  color: #000;
}

.main-ft .bottom-ft a:before {
  content: '';
  position: absolute;
  top: -5px;
  bottom: 0;
  left: -12px;
  width: 1px;
  background-color: #000;
}

.main-ft .bottom-ft a:focus,
.main-ft .bottom-ft a:hover,
.sidebar_bot a:hover{
  color: #477824;
  text-decoration: none;
  cursor: pointer;
}

.sidebar_bot li{
  list-style: none;
}
.sidebar_bot a{
  color: #000;
}

.filterSe{
  position: absolute;
  top:0;
  right:0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 5;
}
.btn_custom_green{
  padding: 13px 18px 8px !important;
  background-color: #9fba5d;
  color: #fff !important;
}
.btn_custom_green2{
  padding: 13px 18px 8px !important;
  color: #9fba5d !important;
  margin: 0 auto !important;
  margin-top: 2% !important;
  font-weight: 900;
}
.fontCustomNav{
  font-size: 1.5rem !important;
}
.vid_cust{
  width: 100%;
  height: 575px;
}
.overSlide{
  position: absolute;
  width: 32%;
  height: auto;
  z-index: 1;
  top: 50%;
  left: 2%;
  padding: 2% 4%;
  background: #fffff6ed;
}
.overSlide h2{
  font-size: 1.35rem;
}
.overSlide h2{
  font-size: 15px;
  margin-bottom: 14px;
}

.relatedProd,
.bestrated,
.relatedProd .owl-stage-outer,
.bestrated .owl-stage-outer,
.relatedProd .owl-stage,
.bestrated .owl-stage{
  width: 100%;
}
.relatedProd .owl-stage,
.bestrated .owl-stage{
  display: inline-flex;
  height: auto;
}
.owl-carousel.owl-drag .owl-item {
  height: auto;
}
.owl-stage{
  height: 375px;
}
.relatedProd,
.bestrated{
  overflow: hidden;
  margin: 0 auto;
}
.relatedProd .owl-stage-outer,
.bestrated .owl-stage-outer{
  height: auto;
  overflow: hidden;
}
.arround_grey{
  position: absolute;
  height: 186px;
  width: 186px;
  background: #b5b5b512;
  border-radius: 50%;
  margin: 10px 16px;
  z-index: 1;
}
.relatedProd .owl-item,
.bestrated .owl-item,
.relatedProd .portfolio-item,
.bestrated .portfolio-item{
  height: auto;
}
.relatedProd .portfolio-item:hover,
.bestrated .portfolio-item:hover{
  cursor: initial;
}
.relatedProd .portfolio-link,
.bestrated .portfolio-link{
  height: auto;
}
.bestrated .owl-nav,
.relatedProd .owl-nav,
.bestrated .owl-prev,
.relatedProd .owl-prev,
.bestrated .owl-next,
.relatedProd .owl-next,
.bestrated .owl-dots,
.relatedProd .owl-dots{
  display: none;
}
button.btn.chevrons_bestrated.next ,
button.btn.chevrons_bestrated.prev {
  background: #fff !important;
  color: #000 !important;
  border: none !important;
}
button.btn.chevrons_bestrated.next:focus,
button.btn.chevrons_bestrated.prev:focus {
  box-shadow: none;
}
.relatedProd .portfolio-link h2,
.bestrated .portfolio-link h2{
  padding: 0 30px;
}
.placeholderCustom{
  border-radius: 50%;
  height: 186px;
  width: 186px;
}
.relatedProd img,
.bestrated img{
  width: 200px;
  height: 200px !important;
}
.relatedProd .portfolio-caption,
.bestrated .portfolio-caption{
  height: 110px;
  margin-top: 8px;
}
.customPopUpBestrated{
  background: #ffffffde;
}
body.modal-open{
  padding-right: 0 !important;
}
.relatedProd .plus__custom,
.bestrated .plus__custom{
  position: absolute;
  width: 83px;
  height: 20px;
  top: 18px;
  padding-left: 28px;
  z-index: 25;
}
.relatedProd .plus__custom:hover,
.bestrated .plus__custom:hover{
  cursor: pointer;
}
.pageTitleRelated h1{
  font-size: 1.3em !important;
  font-family: "Helvetica Std" !important;
  font-weight: bold;
}
.pagenavi a, .pagenavi span{
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 15px;
  border: 2px solid #9FC66D;
  border-radius: 40px;
  background: #9FC66D;
  transition: all 0.3s ease 0s;
}

.dropdownCustom .dropdown-menu .dropdown-item img {
  width: 100%;
  height: 47px;
}
.bioCarousel{
  height: 500px;
}
.bioCarousel iframe,
.bioCarousel img{
  height: 500px !important;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-submenu>a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #ccc;
  margin-top: 5px;
  margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
  border-left-color: #fff;
}

.dropdown-submenu.pull-left {
  float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
.noBackground{
  background: unset;
}
.custom_list.noBackground h2 {
  font-size: 2.625rem;
  margin: 30px 0 10px;
  font-family: "Freeland", Arial, Verdana; }
.custom_list.noBackground .list_ingredients > * {
  float: none;
  margin: 0 -2px; }
.custom_list.noBackground .list_ingredients .col-md-4 {
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  display: inline-block;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  margin: 0 auto;
}
.custom_list.noBackground .list_ingredients .col-md-4 img {
  margin: 0 35px 20px; }
.custom_list.noBackground .list_ingredients .col-md-4 .name_ing_custom {
  font-weight: 700; }
.custom_list.noBackground .list_ingredients .col-md-4 p {
  margin-top: 6px; }
.custom_list.noBackground .btn_div_custom .btn {
  text-decoration: none;
  display: inline-block;
  transition: all .3s ease;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  border-radius: 0; }
.custom_list.noBackground .btn_div_custom a {
  font-size: 1.375rem;
  text-transform: uppercase;
  font-weight: 300;
  background-color: #dfc08c;
  color: #fff; }
.custom_list.noBackground .btn_div_custom a:hover {
  background-color: #595959; }
.center-button {
  text-align: center;
	bottom: 20px;
}

.col-md-4.item__article__only .img_custom_full{
  width:320px;
  height:320px;
}

.row_custom_small.box-1.featured_posts_custom .col-md-4 .img_custom_full{
  width:320px;
  height:320px;
}

.row_custom_small.box-1.featured_posts_custom .col-md-4 .img_custom_full{
  width:320px;
  height:320px;
  }
  
.desc-cn .arrow .alignnone.size-full.wp-image-1241.img_custom_full{
  width:31px; 
  height:75px;
}

.col-md-12.custom__height .logo .img_custom_full{
  width:278px;
  height:30px;
}

.link__custom__item .img-cn .img_custom_full{
  width:600px; 
  height:600px;
}

.col-md-4.col-sm-12.col-xs-12 .img-fluid.img_custom_full{
  width:242px;
  height:242px;
}

.plus__custom .img_custom_full{
  width:22px;
  height:25px;
  }
  
  .Separa-cn .img_custom_full{
  width:598px;
  height:47px;
  }
  
  .col-md-7.c-r.type-num .img_custom_full{
  width:43px;
  height:43px;
  }
  
  .col-md-7.c-l.type-num .img_custom_full{
  width:43px;
  height:43px;
  }
/* -------------  CAROUSEL QUOTES ---------------------- */
.testimonial.style-4 .owl-dots .owl-dot:hover, .testimonial.style-4 .owl-dots .owl-dot.active {
    background: #9fba5d;
}
.testimonial.style-4 .owl-dots .owl-dot {
	display: inline-block;
	margin: 3px;
	width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #c0bfbf;
}

.owl-dots {
    text-align: center;
}


/* --------------------------- HOME CAROUSEL ------------------------- */

.homeCarousel .owl-dots {
	left: 50%;
	top: 90%;
	text-align: center;
	position: absolute;
}

.homeCarousel .owl-carousel .owl-item {
	list-style-type: none;
}

.homeCarousel .owl-dots .owl-dot {
	left: -50%;
	display: inline-block;
	background: transparent;
	border: 1px solid #fff;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 10px;
	height: 10px;
	margin: 3px;
	position: relative;
	cursor: pointer;
}

.homeCarousel .owl-dots .owl-dot.selected, .homeCarousel .owl-dots .owl-dot.active, .homeCarousel .owl-dots .owl-dot:hover {
	background: #fff;
}


/* -------------  CAROUSEL BANNER PARTNERS ---------------------- */

.client-slider .owl-carousel {
	text-align: center;
	overflow:hidden;
}

.client-slider .owl-carousel .owl-stage-outer {
	display:inline-block;
}

.client-slider .owl-nav .owl-prev1::before {
	content: "\44";
	position: relative;
	display: inline-block;
	font-family: 'ElegantIcons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
}

.client-slider .owl-nav .owl-prev1 {
	display: block;
	left: 20px; 
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
	width: 40px;
	height: 40px;
	line-height: 44px;
	font-size: 20px;
	background-color: #9fce4e;
	border: 1px solid #9fce4e;
	text-align: center;
	z-index: 100;
	color: #fff;
	text-decoration: none;
	
	
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
}

.client-slider .owl-nav .owl-next1::before {
	content: "\45";
	position: relative;
	display: inline-block;
	font-family: 'ElegantIcons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	text-decoration: inherit;
}

.client-slider .owl-nav .owl-next1 {
	display: block;
	right: 20px;
	position: absolute;
	top: 50%;
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
	width: 40px;
	height: 40px;
	line-height: 44px;
	font-size: 20px;
	background-color: #9fce4e;
	border: 1px solid #9fce4e;
	text-align: center;
	z-index: 100;
	color: #fff;
	text-decoration: none;
	
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	transition: all .3s ease-out 0s;
}

.client-slider .owl-nav .owl-next1:hover,  .client-slider .owl-nav .owl-prev1:hover {
	background: #fff;
	color: #9fce4e;
}

.client-slider li {
	width: 295px;
	text-align: center;
	float: left;
	padding: 0;
	list-style: none;
	margin: 0 10px;
	
}

.client-slider li a {
	color: #333;
	text-decoration: none;
	background: transparent;
	display: inline-block;
}

.client-slider li a img {
	width: auto	!important;
}



.img_custom_full{
  max-width: 100%;
  height: auto !important;
  vertical-align: middle;
  border: 0;
  outline: none; }

[class*=col-] {
  float: left;
  width: 100%;
  padding: 20px;
  min-height: 1px;
  vertical-align: top;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

ul, li {
  margin: 0;
  padding: 0; }

.main-hd .menu-hd>li>.sub-menu>li.col-md-5:nth-child(2) li {
  width: 50%;
  display: inline-block;
  margin: 0 -2px;
  padding: 0 10px 0 0;
}

.main-hd {
  position: relative;
  z-index: 7;
  font-family: "HelveticaNeueLTStd",Arial,Verdana; }
.main-hd .row_custom_small + .row_custom_small {
  margin-top: 0; }
.main-hd .tool-hd {
  text-align: right; }
.main-hd .tool-hd div:first-child {
  padding-bottom: 0; }
.main-hd .tool-hd div:first-child a {
  font-size: .75rem;
  color: #000;
  display: inline-block;
  text-decoration: none;
  transition: all .3s ease;
  margin-left: 44px; }
.main-hd .tool-hd div:first-child a.current, .main-hd .tool-hd div:first-child a:focus, .main-hd .tool-hd div:first-child a:hover {
  color: #477824; }
.main-hd .tool-hd div:first-child a i {
  margin-right: 9px; }
.main-hd .tool-hd a:first-child {
  margin-left: 0; }
.main-hd .nav-hd .slicknav_menu {
  display: none !important;
  top: 46px;
  position: absolute;
  width: 100%;
  font-size: 16px;
  padding: 0;
  z-index: 1000;
  box-shadow: 0 8px 8px -8px rgba(50, 50, 50, 0.5);
  transition: all .3s ease; }
.main-hd .nav-hd .slicknav_menu [class*=col-] {
  width: 100% !important;
  float: none; }
.main-hd .nav-hd .slicknav_menu .child-nav {
  position: relative;
  padding: 12px 30px; }
.main-hd .nav-hd .slicknav_menu .slicknav_btn {
  margin: 0 0 6px;
  text-decoration: none;
  position: absolute;
  display: block;
  vertical-align: middle;
  padding: 0;
  line-height: 1.125em;
  top: 30px;
  left: 12px;
  /*right: 14px;*/
  z-index: 9; }
.main-hd .nav-hd .slicknav_menu .slicknav_btn .slicknav_menutxt {
  display: none;
  color: #000;
  font-weight: 700;
  line-height: 1.188em;
  float: left; }
.main-hd .nav-hd .slicknav_menu .slicknav_btn .slicknav_icon {
  float: right;
  margin: 0; }
.main-hd .nav-hd .slicknav_menu .slicknav_btn .slicknav_icon .slicknav_icon-bar {
  background-color: #000;
  transition: all .3s ease;
  display: block;
  width: 1.5em;
  height: .18em;
  margin-bottom: 5px; }
.slicknav_btn.slicknav_open .slicknav_icon-bar {
  background-color: #000;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 30% 50%;
          transform-origin: 30% 50%;
  width: 30px;
  margin-top: 5px;
}
.slicknav_btn.slicknav_open .slicknav_icon-bar:first-child {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.slicknav_btn.slicknav_open .slicknav_icon-bar {
  background-color: #000;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 30% 50%;
          transform-origin: 30% 50%;
  width: 30px;
  margin-top: 5px;
}
.slicknav_btn.slicknav_open .slicknav_icon-bar:last-child{
  opacity: 0;
}
.main-hd .nav-hd .slicknav_menu .slicknav_nav,
.main-hd .nav-hd .slicknav_menu .slicknav_nav ul {
  list-style: none;
  overflow: hidden; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav {
  font-size: .875em;
  background-color: #fff;
  padding: 0;
  margin-top: 54px !important;
  clear: both; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav:first-child {
  padding-top: 24px;
  border-top: none; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav .slicknav_arrow {
  font-size: .8em;
  margin: 0 0 0 .4em;
  display: none; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav ul {
  padding: 0;
  margin: 0; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li {
  background-color: rgba(255, 255, 255, 0.8);
  text-align: left;
  border-top: 1px solid #c5c5ca;
  padding-left: 4px;
  display: block;
  height: 100%;}
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li:hover {
  background-color: #ebebeb; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li > p {
  padding: 14px; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li > p > a {
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  padding: 0;
  margin: 0;
  display: inline; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li > p .slicknav_arrow {
  font-size: .8em;
  margin: 0 0 0 .4em;
  display: none; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li.current a,
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li:hover a {
  color: #000; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav ul li,
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li > a,
.main-hd .nav-hd .slicknav_menu .slicknav_nav > li > p {
  padding: 14px;
  display: block;}
.main-hd .nav-hd .slicknav_menu .slicknav_nav .sub-menu {
  background-color: #f5f5f5 !important; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav .child-nav {
  position: relative;
  padding: 12px 30px; }
.main-hd .nav-hd .slicknav_menu .slicknav_nav .child-nav:first-child {
  padding-top: 24px;
  border-top: none; }
.slicknav_menu .child-nav>a:before,
.slicknav_nav >a:before {
  content: '\f067';
  margin-right: 8px;
  vertical-align: middle;
  font-family: FontAwesome;
  font-size: 10px;
}
.slicknav_nav a:last-child:before{
  display: none;
}
.slicknav_nav a {
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  position: relative;
}
.main-hd .nav-hd .nav-cn {
  display: inline-block;
  text-align: center;
  padding-top: 0;
  padding-bottom: 0; }
.main-hd .nav-hd .nav-cn nav {
  display: block; }
.main-hd .nav-hd .nav-cn .menu-hd {
  line-height: 14px;
  margin-bottom: 0; }
.main-hd .nav-hd .nav-cn .menu-hd > li.menu-item-has-children:focus > a:before,
.main-hd .nav-hd .nav-cn .menu-hd > li.menu-item-has-children:hover > a:before {
  content: "";
  position: absolute;
  z-index: 1000;
  border-top: 7px solid #477824;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  left: 0;
  right: 0;
  margin: auto;
  width: 14px;
  bottom: -24px; }
.main-hd .nav-hd .nav-cn .menu-hd > li.menu-item-has-children:hover > a {
  color: #000;
  background-color: #f0f0f0;
  position: relative; }
.main-hd .nav-hd .nav-cn .menu-hd > li:first-child {
  margin-left: 0; }
.main-hd .nav-hd .nav-cn .menu-hd > li.current-menu-ancestor > a,
.main-hd .nav-hd .nav-cn .menu-hd > li.current-menu-item > a {
  color: #477824 !important; }
.main-hd .nav-hd .nav-cn .menu-hd > li {
  position: relative;
  display: inline-block;
  margin-left: 100px; }
.main-hd .nav-hd .nav-cn .menu-hd > li:hover {
  background-color: #f0f0f0; }
.main-hd .nav-hd .nav-cn .menu-hd > li:hover .sub-menu {
  display: block; }
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu {
  display: none;
  position: absolute;
  top: 40px;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 99;
  text-align: left;
  padding: 68px 20px 20px;
  left: -452px;
  width: 1008px;}
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu > li {
  position: relative;
  padding-left: 30px;
  list-style-type: none;}
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu > li > a {
  font-weight: 400;
  /*text-transform: uppercase; */
}
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu > li:after {
  content: '';
  position: absolute;
  top: 50px;
  bottom: 24px;
  right: 0;
  width: 1px;
  background-color: #979797; }
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu > li:last-child:after{
  display: none
}
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu > li:last-child:after {
  display: none; }
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu .sub-menu {
  margin-top: 14px; }
.main-hd .nav-hd .nav-cn .menu-hd > li > .sub-menu .sub-menu li {
  line-height: 24px;
  list-style-type: none; }
.main-hd .nav-hd .nav-cn .menu-hd > li:hover:last-child {
  background-color: unset; }
.main-hd .nav-hd .nav-cn .menu-hd > li > a {
  position: relative;
  z-index: 100;
  text-transform: uppercase;
  font-size: .875rem;
  padding: 22px 30px 17px;
  display: block;
  color: #000;
  text-decoration: none;
  transition: all .3s ease;
  font-family: "HelveticaNeueLTStd",Arial,Verdana;
  font-weight: 400; }
.main-hd .nav-hd .nav-cn .menu-hd > li .sub-menu {
  display: none; }
.main-hd .nav-hd .nav-cn .menu-hd a {
  color: #000;
  text-decoration: none;
  transition: all .3s ease; }
.main-hd .nav-hd .nav-cn .menu-hd a:focus, .main-hd .nav-hd .nav-cn .menu-hd a:hover {
  color: #477824 !important; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) > .sub-menu {
  /*width: 1008px;*/
  left: -215px; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) > .sub-menu > li {
  position: relative;
}
.main-hd .menu-item-has-children > a:first-child{
  text-transform: uppercase;
}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) > .sub-menu > li > a {
  font-weight: 400;
  color: #000; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1):hover > .sub-menu{
  display: block !important;
  /*height: 482px;*/
}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) > .sub-menu > li{
  /*width: 250px;*/
}
.menu-hd li:nth-child(1) > .sub-menu .buscar__custom{
  width: 100% !important;
}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) > .sub-menu > li .sub-menu {
  margin-top: 14px;
  width: 100% !important;}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) > .sub-menu > li .sub-menu li {
  font-weight: 300;
  line-height: 24px;
  width: 100%;}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) > .sub-menu > li .sub-menu li a {
  color: #000; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) .child-nav {
  min-height: 360px; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) .no_border__custom:after {
  display: none; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) .buscar__custom {
  text-align: center;
  padding: 12px 20px 6px;
  border: 1px solid;}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) .buscar__custom:hover{
  border-color: #595959;
  color: #fff !important;
  background-color: #595959;
cursor: pointer;}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) .buscar__custom a {
  color: #000 !important;
}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) .buscar__custom:hover a{
  color: #fff !important;
}
.main-hd .nav-hd .nav-cn .menu-hd > li:nth-child(2) > .sub-menu > li{
  width: 240px;
}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(2) > .sub-menu {
  min-width: 300px;}
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(2) > .sub-menu li {
  line-height: 24px; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(3) > .sub-menu {
  /*width: 800px;*/
  left: -480px; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(3) .child-nav {
  min-height: 170px; }
li.no_border__custom{
  padding-right: 0px;
}
li.no_border__custom a{
  font-size: 15px;
}
li.no_border__custom .sub-menu {
  width: auto !important;
}
.liveraw-nav ul li a{
  font-family: "Helvetica Lt",Arial,Verdana!important
}
.main-hd .nav-hd > * {
  float: none;
  display: inline-block;
  margin: 0 -2px;
  vertical-align: bottom; }

.main-hd .nav-hd .logo {
  position: relative;
  z-index: 1001;
  max-width: 100%;
  display: inline-block; }
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(1) .sub-menu li:nth-child(2) .sub-menu li{
  width: 50%;
  display: inline-block;
  margin: 0 -2px;
  padding: 0 10px 0 0;
}
.main-hd .nav-hd .nav-cn li:nth-child(2){
  max-width: 316px;
}
.main-hd .nav-hd .nav-cn li:nth-child(3){
  max-width: 328px;
}
.main-hd .nav-hd .nav-cn .menu-hd > li:nth-child(1) .sub-menu > .col-md-4{
  height: auto;
}
/*.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(3) > .sub-menu{*/
/*  width: auto;*/
/*}*/
.main-hd .nav-hd .nav-cn .menu-hd li:nth-child(3) > .sub-menu > li{
  min-height: 170px;
  max-width: 25%;
  padding: 20px;
}
.main-hd .nav-hd .nav-cn .menu-hd > li:nth-child(3) .sub-menu > li:nth-child(3)::after{
  display: none;
}

.main-hd li:first-child .sub-menu li:nth-child(0n+3) .sub-menu > li{
  width: 50% !important;
  display: inline-block;
  margin: 0 -2px;
  padding: 0 10px 0 0;
}

@media (min-width: 350px) {
  .slicknav_menu {
    top: 46px; } }
@media (min-width: 1005px) {
  .slicknav_menu {
    display: none !important; } }

/*# sourceMappingURL=headermultilevels.css.map */

.menu-hd li:nth-child(2):hover .sub-menu.first_level,
.menu-hd li:first-child:hover .sub-menu.first_level{
  display: inline-flex !important;
}
li.menu-item.col-md-4.menu-item-has-children:nth-child(n+3)::after{
  display: none !important;
}

.menu-hd li:first-child .sub-menu{
  width: 1008px !important;
}

.cat_nav:hover{
  cursor: pointer;
}
.our__philosophy h1{
    font-size: 4.6875rem;
    color: #9fba5d;
    font-family: "PopulaireLight",Arial,Verdana;
    text-transform: uppercase;
    margin-bottom: 26px;
}

.our__philosophy blockquote{
    font-weight: 300;
}



.sidebar-wrap {
  position: relative;
  top: initial;
  bottom: initial;
  right: initial !important;
  box-shadow: none;
  z-index: 1;
  background-color: #fff;
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.3s ease;
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
}
.sidebar-wrap .filters-control {
  width: 10%;
  display: inline-flex;
}
.sidebar-wrap .main-sidebar {
  padding: 0;
  overflow: hidden;
  height: 100%;
}
.sidebar-wrap .widget_search {
  display: none;
}
.sidebar-wrap .clear-filters {
  font-size: 0.875rem;
  text-align: left;
  padding: 0 0 18px 0;
  font-weight: bolder;
  width: 90%;
  display: inline-flex;
}
.sidebar-wrap h2 {
  border-bottom: 1px solid #000;
  padding-bottom: 4px;
  margin-bottom: 16px;
  cursor: pointer;
  pointer-events: none;
  font-size: 0.875rem;
  font-weight: 300;
  display: block;
}
.sidebar-wrap .product-categories li {
  margin-bottom: 10px;
  width: 235px;
  height: 25px;
}
.sidebar-wrap .product-categories li a {
  position: relative;
  color: #000 !important;
  padding: 3px 4px 2px 24px;
  border-right: 2px solid #fff;
  display: block;
  font-size: 0.875rem;
  font-weight: 300;
  text-decoration: none;
  transition: all 0.3s ease;
}
.sidebar-wrap .product-categories li a:hover {
  cursor: pointer !important;
  border-right-color: #9fba5d;
  background-color: #fafafa;
}
.sidebar-wrap .product-categories li a:before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 6px;
  height: 6px;
  background-color: #dfdfdf;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.3s ease;
}
.sidebar-wrap .product-categories li a.selected:before {
  background-color: #000;
}

.custom__all_prods h1, .custom__all_prods h2 {
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 40px;
}
.custom__all_prods h1, .custom__all_prods h2 {
  display: inline-flex;
}
.custom__all_prods h2 {
  font-weight: 700;
}
.custom__all_prods .list__product__item {
  margin-top: 7px !important;
}
.custom__all_prods .list__product__item .Producto-item {
  padding: 25px;
  margin: 0 0px;
  margin-bottom: 26px;
}
.custom__all_prods .list__product__item .Producto-item .star-cn .fa {
  font-size: 0.875rem;
  margin: 0 3px 20px;
}
.custom__all_prods .list__product__item .Producto-item .btn_custom_oro {
  padding: 0px 10px;
}
.custom__all_prods .list__product__item .commerce-pagination {
  margin-top: 10px;
  margin-bottom: 80px;
  text-align: center;
  padding: 0 10px;
  width: 100%;
  display: block;
}

.display_filter_prod {
  display: none;
}

.title-search__custom {
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 40px;
}

.modal {
  padding-right: unset;
}
.modal .modal-content.ingre_modal {
  width: 900px;
  height: 300px;
  padding: unset;
}
.modal .modal-content.ingre_modal .modal-body {
  padding: unset;
}
.modal .modal-content.ingre_modal .modal-body h2 {
  padding-right: 40px;
}
.modal .modal-content.ingre_modal .modal-body .star-cn {
  padding-right: 40px;
  margin-top: unset;
}
.modal .modal-content.ingre_modal .modal-body .fa {
  font-size: 0.875rem;
  margin: 0 3px 20px;
}
.modal .modal-content.ingre_modal .modal-body h1 {
  display: none;
}
.modal .modal-content.ingre_modal .modal-body p:nth-child(2) {
  margin-bottom: 0;
}
.modal .modal-content.ingre_modal .modal-body .pop_description p {
  font-size: 0.775rem;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 30px;
}
.modal .modal-content.ingre_modal .modal-body .btn_custom_oro {
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  line-height: normal;
  padding: 6px 18px 2px;
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  border: none;
  height: 31px;
  text-transform: none;
}

.modal-body,
.container-fluid {
  height: 100%;
}

.commerce-pagination {
  font-size: 1.125rem;
}

.dropdownCustom .dropdown-menu,
.dropdownCustom .dropdown-item {
  padding: unset !important;
}

.dropdownCustom .dropdown-menu {
  background: #fff !important;
}
.dropdownCustom .dropdown-menu .dropdown-item {
  border-bottom: 1px solid;
  text-align: center;
  padding: 5px 0 !important;
  display: block;
  font-size: 15px;
}
.dropdownCustom .dropdown-menu .dropdown-item:last-child {
  border-bottom: unset !important;
}
.dropdownCustom .dropdown-menu .dropdown-item img {
  width: 174px !important;
  height: 26px !important;
}

/*# sourceMappingURL=customProductFinder.css.map */

.row_custom_small.box-1 .row_custom_small.box-1 + .row_custom_small.box-1,
.box-project .row_custom_small.box-1 + .row_custom_small.box-1 {
  margin-top: unset !important;
}
.row_custom_small.box-1 .row_custom_small,
.box-project .row_custom_small {
  padding: unset !important;
  margin-bottom: 34px;
}
.row_custom_small.box-1 .row_custom_small .pad,
.box-project .row_custom_small .pad {
  padding: unset;
  margin-top: unset !important;
  margin-bottom: unset !important;
}
.row_custom_small.box-1 .row_custom_small .pad h1,
.box-project .row_custom_small .pad h1 {
  font-size: 2.625rem;
  margin: 12px 0 17px;
  text-align: center;
}
.row_custom_small.box-1 .row_custom_small .pad h1,
.box-project .row_custom_small .pad h1 {
  margin-bottom: 40px !important;
}
.row_custom_small.box-1 .row_custom_small .pad blockquote,
.box-project .row_custom_small .pad blockquote {
  margin: unset !important;
  max-width: 100%;
  text-align: center;
  margin-bottom: 34px !important;
  font-size: 1.375rem;
  line-height: 30px;
  font-weight: 300;
}
.row_custom_small.box-1 .row_custom_small .pad blockquote p,
.box-project .row_custom_small .pad blockquote p {
  margin-bottom: unset;
}
.row_custom_small.box-1 .row_custom_small .pad .home__img_marg,
.box-project .row_custom_small .pad .home__img_marg {
  margin-top: unset !important;
}

.row_custom_small.box-1 {
  background: #80808021;
  padding: 0 8%;
}
.row_custom_small.box-1 .row_custom_small.section3 {
  margin-bottom: unset !important;
}

.row_custom_small.box-1.featured_posts_custom {
  background: initial;
}

.row_custom_small.box-1 + .row_custom_small.box-1 {
  margin-top: unset !important;
}

.single-product-images {
  height: 563px;
}
.single-product-images .product--gallery__trigger {
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
}
.single-product-images .carousel-slider {
  overflow: hidden;
  position: relative;
  height: 418px;
  width: 100%;
}

#lightboxBackdrop:hover .arrow_b9bbag-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo, #lightboxBackdrop:hover .arrow_b9bbag-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo {
  opacity: 1;
}
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo,
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo {
  -webkit-backface-visibility: hidden;
  will-change: opacity;
  transition: opacity 333ms cubic-bezier(0.4, 0, 0.22, 1);
  visibility: visible;
  opacity: 0;
  background: none;
  top: 44%;
  margin-top: -50px;
  position: absolute;
  cursor: pointer;
  overflow: visible;
  -webkit-appearance: none;
  display: block;
  border: 0;
  padding: 0;
  margin: 0;
  float: right;
}
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo svg,
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo svg {
  content: "";
  top: 35px;
  height: 30px;
  width: 32px;
  position: absolute;
}
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo {
  right: 0;
}
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__right_174p6a9-o_O-arrow__size__medium_9f7hgo svg {
  left: 6px;
  background-position: -138px -44px;
}
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo {
  left: 0;
}
#lightboxBackdrop .arrow_b9bbag-o_O-arrow__direction__left_shhpn5-o_O-arrow__size__medium_9f7hgo svg {
  right: 6px;
  background-position: -94px -44px;
}

.carousel_custom_product_5 .carousel {
  position: relative;
  zoom: 1;
  display: block;
}

.carousel_custom_product_7 .summary {
  height: auto;
}
.carousel_custom_product_7 .summary h1 {
  font-size: 2.125rem;
  font-weight: 400;
  font-family: unset;
  text-transform: uppercase;
  margin-bottom: 0;
}
.carousel_custom_product_7 .summary p.meta-vol {
  font-size: 1.375rem;
  line-height: 33px;
  font-family: "Helvetica Md", Arial, Verdana;
  margin-bottom: 10px;
  color: #9fba5d;
}
.carousel_custom_product_7 .summary .commerce-product-rating {
  height: 46px;
  margin-top: -4px;
}
.carousel_custom_product_7 .summary .commerce-product-rating p {
  margin-bottom: 0 !important;
}
.carousel_custom_product_7 .summary .commerce-product-rating p i {
  margin-right: 8px;
  font-size: 14px;
}
.carousel_custom_product_7 .summary .commerce-product-rating .commerce-product-review-link {
  color: #9fba5d !important;
}
.carousel_custom_product_7 .summary .commerce-product-rating .commerce-product-review-link strong {
  font-size: 1rem;
  font-weight: 700;
  display: block;
  margin-top: -2px;
}
.carousel_custom_product_7 .summary .commerce-product-rating .commerce-product-review-link:hover {
  text-decoration: none;
  color: #477824;
}
.carousel_custom_product_7 .summary .product-excerpt p {
  margin-bottom: 34px;
  font-size: 1.125rem;
  line-height: 30px;
  font-weight: 400;
}
.carousel_custom_product_7 .summary .product-excerpt p:last-child {
  margin-bottom: 3px;
}
.carousel_custom_product_7 .summary .meta-cn p {
  margin-bottom: 14px;
}
.carousel_custom_product_7 .summary .meta-cn p:nth-child(2) {
  margin-bottom: 11px;
}
.carousel_custom_product_7 .summary .meta-cn p strong {
  display: inline-block;
  font-family: "Freeland", Arial, Verdana;
  font-size: 1.5rem;
  width: 230px !important;
  font-weight: 500;
}
.carousel_custom_product_7 .summary .meta-cn p .key_ingre,
.carousel_custom_product_7 .summary .meta-cn p .double_cat {
  display: inline-block;
  width: 360px;
  vertical-align: top;
  font-size: 21px;
  line-height: 27px;
  font-family: unset;
}
.carousel_custom_product_7 .summary .meta-cn p .key_ingre span,
.carousel_custom_product_7 .summary .meta-cn p .double_cat span {
  width: auto;
}
.carousel_custom_product_7 .summary .meta-cn p i {
  display: inline-block;
  position: relative;
}
.carousel_custom_product_7 .summary .meta-cn p i select {
  line-height: 30px;
  font-size: 1.375rem;
  font-weight: 400;
  background-color: transparent;
  border: 1px #000 solid;
  padding: 12px 12px 8px;
  padding-right: 60px;
  color: #000;
  font-family: unset;
  outline: 0;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  -ms-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
}
.carousel_custom_product_7 .summary .meta-cn p i select:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.02);
  border-color: #477824;
}
.carousel_custom_product_7 .summary .meta-cn p i.select i {
  border-left: 1px solid #000;
  pointer-events: none;
  width: 34px;
  line-height: 56px;
  font-size: 1.25rem;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: block;
}
.carousel_custom_product_7 .summary .meta-cn a {
  text-transform: uppercase;
  font-size: 1.375rem;
  font-weight: 300;
  margin-top: 20px;
  background-color: #dfc08c;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s ease;
  text-align: center;
  cursor: pointer;
  line-height: normal;
  padding: 8px 18px 4px;
  font-family: "HelveticaNeueLTStd", Arial, Verdana;
  border: none;
  border-radius: 0;
}
.carousel_custom_product_7 .summary .meta-cn a:hover {
  background-color: #595959;
  color: #fff;
}
.carousel_custom_product_7 .linksProd {
  padding-top: 40%;
}

.dropdownCustom {
  display: inline-flex;
}
.dropdownCustom .dropdown-toggle {
  padding: 14px 14px 14px 12px !important;
  border: unset !important;
  width: 200px !important;
}
.dropdownCustom .dropdown-toggle:after {
  margin-left: 20px;
}
.dropdownCustom .dropdown-menu {
  background: #f1f1f1;
  width: 200px;
}
.dropdownCustom .dropdown-menu .dropdown-item {
  padding: 6px 6px;
}
.dropdownCustom .dropdown-menu .dropdown-item img {
  width: 100%;
}

.showListProduct {
  width: 77%;
  height: 100px;
  display: inline-flex;
  padding-left: 0;
  padding-right: 0;
  margin: 0 30px;
}
.showListProduct .advantages {
  height: 100px;
  padding: 5px;
  text-align: center;
}
.showListProduct .advantages img {
  height: 32px;
  width: 32px;
  margin: 0 30%;
}
.showListProduct .advantages span {
  color: #b3b3b3 !important;
  font-size: 12px;
  font-weight: bold;
}

#product_accordion .card {
  border: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
#product_accordion .card:last-child {
  border-bottom: unset;
  margin-bottom: 10px;
}
#product_accordion .card .card-header {
  padding: unset;
  background: unset;
  border: unset;
  text-align: left;
}
#product_accordion .card .card-header .btn {
  padding: unset;
  text-decoration: none;
  color: #9fba5d;
  font-size: 14px;
  border: unset !important;
}
#product_accordion .card .card-header .btn i {
  font-size: 12px;
  color: #9fba5d;
}
#product_accordion .card .card-header .btn .fa-plus {
  display: none;
}
#product_accordion .card .card-header .btn .fa-minus {
  display: inline-flex;
}
#product_accordion .card .card-header .btn.btn-link {
  background: unset !important;
}
#product_accordion .card .card-header .btn.nohover:hover {
  cursor: initial;
}
#product_accordion .card .card-header .collapsed {
  color: #b3b3b3 !important;
}
#product_accordion .card .card-header .collapsed i {
  color: #b3b3b3 !important;
}
#product_accordion .card .card-header .collapsed .fa-plus {
  display: inline-flex;
}
#product_accordion .card .card-header .collapsed .fa-minus {
  display: none;
}
#product_accordion .card .dropdownCustom .btn {
  border: #dfc08c !important;
}
#product_accordion .card .dropdownCustom .dropdown-menu .dropdown-item img {
  width: 100%;
  height: 47px;
}

.single-product-images .carousel .slide img:hover {
  cursor: pointer;
}

.socialLink a {
  width: 26px;
  height: 30px;
}
.socialLink a:hover {
  opacity: 0.8;
}
.socialLink a i {
  font-size: 30px;
  padding: 0 35px;
  color: #b3b3b3;
}
.socialLink a i:last-child {
  padding-right: 0;
}

/*# sourceMappingURL=customProductOnly.css.map */

.storeMap {
  height: auto;
  font-size: .875rem;
  font-weight: 300;
  padding: 12px 7px 10px;
  border-bottom: 1px solid #000;
  line-height: 22px;
  text-align: left; }
  .storeMap i {
    color: #dfc08c;
    font-size: 1.225rem;
    line-height: 26px; }
  .storeMap strong {
    font-weight: 700;
    display: block;
    font-size: .9375rem;
    text-transform: uppercase; }
  .storeMap a {
    border: 1px solid #000;
    display: inline-block;
    padding: 8px 30px 4px 14px;
    color: #000;
    margin-top: 10px;
    font-size: .9375rem;
    text-transform: uppercase; }
    .storeMap a i {
      margin-right: 30px; }

.div_ppp_searchbox {
  left: unset !important;
  right: 10% !important;
  top: -17px !important;
  display: flex;
  flex-direction: row;
  border: 1px solid #000;
  width: 350px;
  box-sizing: border-box;
  height: 40px;
  margin-top: 27px;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipses; }
  .div_ppp_searchbox input {
    padding: 10px;
    border: none;
    flex-grow: 2; }
  .div_ppp_searchbox .btn_geo {
    border: 1px solid #000;
    background: #151515;
    color: white;
    padding: 1px 6px; }
    .div_ppp_searchbox .btn_geo img {
      height: 25px; }

.rest_map {
  color: white;
  padding: 6px 15px;
  border-radius: 2px;
  font-size: 9pt;
  outline: none; }
  .rest_map i {
    margin-right: 30px;
    font-size: 14px;
    color: #000; }
  .rest_map:hover {
    cursor: pointer;
    background-color: #595959;
    color: #fff !important; }
    .rest_map:hover i {
      color: #fff; }

.storeList {
  background: #fff; }

.moreStore {
  height: 520px;
  overflow-y: auto; }

.loadingElement {
  height: 100%; }

.containerElement {
  height: 520px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 2%;
  margin-top: 50px; }

.mapElement {
  width: 100%;
  height: 100%;
  order: 2;
  flex-grow: 1; }

.moreStore::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5; }

.moreStore::-webkit-scrollbar {
  width: 8px;
  height: 6px;
  background-color: #F5F5F5; }

.moreStore::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555; }

/*# sourceMappingURL=bioStoreLocator.css.map */

@media screen and (max-width: 769px) {
    .changeHamburger {
        float: right;
        margin: 0;
    }

        .changeHamburger .slicknav_icon-bar {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            -webkit-transform-origin: 30% 50%;
                    transform-origin: 30% 50%;
            width: 30px;
            margin-top: 5px;
            display: block;
            margin-bottom: -1px !important;
        }

            .changeHamburger .slicknav_icon-bar:first-child {
                -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
            }

            .changeHamburger .slicknav_icon-bar:last-child {
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=$IEValue);
                filter: alpha(opacity=0);
                -moz-opacity: 0;
                -khtml-opacity: 0;
                opacity: 0;
            }

    .slicknav_menu #navigation {
        margin-top: 65px !important;
    }

        .slicknav_menu #navigation .producto-nav a:before {
            font-family: "FontAwesome";
            content: "\f067";
            padding-right: 8px;
            font-size: 10px;
            vertical-align: middle;
        }

        .slicknav_menu #navigation .producto-nav .slicknav_nav .sub-menu {
            background-color: #f5f5f5 !important;
        }

        .slicknav_menu #navigation .producto-nav .child-nav {
            position: relative;
            padding: 3px 30px;
        }

            .slicknav_menu #navigation .producto-nav .child-nav:first-child {
                padding-top: 24px;
                border-top: none;
            }

        .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom {
            padding: 12px 2px;
        }

            .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom:first-child {
                padding-top: 24px;
            }

            .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom > a {
                font-size: .75rem;
            }

                .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom > a:after {
                    content: "\f068";
                    margin-left: 8px;
                    vertical-align: middle;
                    font-family: "FontAwesome";
                    font-size: 10px;
                }

        .slicknav_menu #navigation li {
            max-width: 100%;
        }

    #navigation .producto-nav:nth-child(2) li:after {
        display: none;
    }

    .main-hd .tool-hd {
        text-align: center;
    }

        .main-hd .tool-hd .col-md-12 {
            text-align: center;
        }

        .main-hd .tool-hd a {
            margin-left: 20px !important;
        }

            .main-hd .tool-hd a:first-child {
                margin-left: 0px !important;
            }

    .main-hd .nav-hd .slicknav_menu {
        display: block !important;
    }

    .main-hd .nav-hd .logo {
        max-width: 200px;
    }

    .main-hd .nav-hd .nav-cn {
        display: none;
    }

    .owl-stage {
        height: unset;
    }

    li:first-child .sub-menu li:nth-child(0n+3) .sub-menu > li {
        display: inherit !important;
    }

    .cat__custom__article ul li {
        width: 100%;
    }

    .main-ft .bottom-ft a:before {
        display: none;
    }

    .main-ft .bottom-ft {
        padding: 8px;
        height: unset;
    }

        .main-ft .bottom-ft a {
            padding: 8px;
        }

    .custom_list .list_ingredients .col-md-4 img {
        margin: unset !important;
    }

    .list__product__item .Producto-item {
        padding: 0;
        height: unset !important;
    }

    .filters-control.display_filter_prod {
        display: block;
        position: absolute;
        z-index: 2;
        right: 18px;
        top: 16%;
    }

    .related__product__custom .portfolio-link {
        height: unset !important;
    }

    .bioCarousel img,
    .bioCarousel iframe {
        height: inherit;
    }

    #main_custom .section2 .portfolio-caption,
    #main_custom .section3 .portfolio-caption,
    #main_custom .section4 .portfolio-caption {
        height: auto;
    }

    .bioCarousel {
        height: initial;
        padding-top: 25px;
    }

    .single-product-images .carousel, .single-product-images .carousel.carousel-slider {
        width: initial !important;
        margin: initial;
    }

    .linksProd .socialLink {
        text-align: left !important;
    }

    .dropdownCustom {
        margin-left: -6px;
    }

        .dropdownCustom .dropdown.ml-4 {
            margin-left: 5px !important;
        }

        .dropdownCustom .dropdown-toggle {
            padding: 14px 14px 14px 12px !important;
            width: 146px !important;       
        }

            .dropdownCustom .dropdown-toggle:after {
                margin-left: 10px;
            }

    .showListProduct {
        width: 100%;
        margin: unset;
        display: block;
        height: unset;
    }
}

@media screen and (max-width: 320px) {
    .mobile_view {
        height: 92vh !important;
    }

        .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt {
            position: absolute;
            width: 100%;
            height: auto;
            display: inline-block;
            padding-top: 116%;
        }

            .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt > div {
                padding: 0;
            }

                .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt > div p {
                    text-align: left;
                }

        .mobile_view .col-md-5.c-r.img-cn.mobile_view_img {
            position: absolute;
            width: 100%;
            height: auto;
            display: inline-block;
            padding-top: 14%;
        }

    .history .col-md-7.c-r.type-num {
        padding-top: 2px;
    }

    .main-ft {
        height: 100%;
    }

        .main-ft .bottom-ft {
            height: 100%;
        }

            .main-ft .bottom-ft p span {
                width: 260px !important;
            }

        .main-ft .bottom {
            margin-top: 20% !important;
        }

    #main_custom .section2 h2 {
        padding: 4% 0;
        font-size: 16px;
    }

    #main_custom .btn_custom {
        padding: 14px 10px 4px !important;
        width: 150px !important;
        margin-top: 15%;
    }

    .main-hd .tool-hd .col-md-12 {
        padding: 20px 0;
    }

    .main-hd .tool-hd a {
        margin-left: 12px !important;
    }

    .related__product__custom h1 {
        font-size: 1.8rem;
    }

    .bestrated .arround_grey,
    .relatedProd .arround_grey {
        margin: 0px 47px;
    }

    .relatedProd .placeholderCustom,
    .bestrated .placeholderCustom {
        width: 184px;
        height: 184px !important;
        margin: -7px 0;
    }

    .storeList .containerElement {
        margin: 6% 2%;
    }

        .storeList .containerElement .moreStore {
            height: 176px;
            overflow-y: auto;
            width: 250px;
            position: absolute;
            bottom: 18%;
        }

    .div_ppp_searchbox {
        right: 2% !important;
        top: 28px !important;
        width: 250px;
    }

    .gm-style-iw,
    .gm-style-iw-d {
        max-width: 230px !important;
    }

    .dropdownCustom .dropdown-toggle {
        padding: 14px 14px 14px 12px !important;
    }

    .dropdownCustom .dropdown:last-child .dropdown-menu {
        left: -68px !important;
    }

    .custom_list h2 {
        font-size: 1.25em;
    }

    .custom_list .list_ingredients {
        display: block;
    }

        .custom_list .list_ingredients .desc {
            margin: unset !important;
        }

    .custom_list .btn_div_custom a {
        width: 100% !important;
    }

    .relatedProd .btn_custom_oro {
        margin-top: 24px;
    }

    .customPopUpBestrated {
        width: 92%;
    }

    .cat__custom__article ul li a span {
        margin: 20% 0 20% 28%;
    }

    .overSlide {
        width: 75%;
    }

        .overSlide h2 {
            font-size: 0.975rem;
        }

    .bestRatedProd h1 {
        font-size: 1.5em;
    }

    .chevrons_bestrated {
        margin-top: 50%;
        position: absolute;
    }

    .bestRatedProd .chevrons_bestrated {
        margin-top: 65%;
    }

    .chevrons_bestrated.prev {
        left: 10%;
    }

    .chevrons_bestrated.next {
        right: 10%;
    }

    .bioCarousel iframe, .bioCarousel img {
        height: 250px !important;
    }

    .owl-carousel .owl-item img {
        height: 250px !important;
    }

    .showListProduct .advantages img {
        margin: 0 44%;
    }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
    .mobile_view {
        height: 95vh;
    }

        .mobile_view .col-md-7.c-l.text-cn.mobile_view_txt {
            padding-top: 107%;
        }

        .mobile_view .col-md-5.c-r.img-cn.mobile_view_img {
            position: absolute;
            width: 100%;
            height: auto;
            display: inline-block;
            padding-top: 12%;
        }

    .main-ft {
        height: 100% !important;
    }

        .main-ft .bottom-ft p span {
            width: 260px !important;
        }

        .main-ft .bottom {
            margin-top: 5% !important;
        }

    .carousel_custom_product_7 .summary .meta-cn p strong {
        width: 170px !important;
    }

    .carousel_custom_product_7 .summary .meta-cn p span {
        width: 120px !important;
    }

    .arround_grey {
        margin: 0px 72px;
    }

    .related__product__custom .btn_custom_oro {
        width: 35% !important;
    }

    /* .cat__custom__article ul li a span {
    margin: 18% 0 18% 31%; } */

    .storeList .containerElement .moreStore {
        height: 180px;
        overflow-y: auto;
        width: 250px;
        position: absolute;
        bottom: 10%;
    }

    .div_ppp_searchbox {
        right: 3% !important;
        top: 28px !important;
        width: 280px;
    }

    .overSlide {
        width: 70%;
        right: 3%;
    }

        .overSlide h2 {
            font-size: 0.975rem;
        }

    .main-ft .bottom-ft a {
        width: 80%;
    }

    .chevrons_bestrated {
        margin-top: 50%;
        position: absolute;
    }

    .bestRatedProd .chevrons_bestrated {
        margin-top: 60%;
    }

    .chevrons_bestrated.prev {
        left: 10%;
    }

    .chevrons_bestrated.next {
        right: 10%;
    }

    .bioCarousel iframe, .bioCarousel img {
        height: 250px !important;
    }

    .linksProd .socialLink {
        text-align: center !important;
    }

    .custom_list .list_ingredients {
        display: block;
    }

    .showListProduct .advantages img {
        margin: 0 44%;
    }
}

@media screen and (min-width: 376px) and (max-width: 425px) {
    .wrapper {
        margin: unset;
    }

    #main_custom h1 {
        font-size: 1.5625rem;
        margin: unset;
    }

    #main_custom .all_category_product {
        margin-top: 6px;
    }

        #main_custom .all_category_product h1 {
            padding-left: 4px;
        }

        #main_custom .all_category_product h1 {
            font-size: 4.6875rem !important;
        }

    #main_custom .search__custom h1 {
        font-size: 1.875rem;
    }

    #main_custom .section2 .portfolio-caption,
    #main_custom .section3 .portfolio-caption,
    #main_custom .section4 .portfolio-caption {
        margin-bottom: 0;
    }

        #main_custom .section2 .portfolio-caption h2,
        #main_custom .section3 .portfolio-caption h2,
        #main_custom .section4 .portfolio-caption h2 {
            font-weight: bold;
            font-size: 1.125rem;
        }

        #main_custom .section2 .portfolio-caption p,
        #main_custom .section3 .portfolio-caption p,
        #main_custom .section4 .portfolio-caption p {
            font-size: 1rem;
            margin-bottom: 0;
        }

    #main_custom .btn_custom {
        padding: 14px 10px 4px !important;
        width: unset !important;
    }

    #main_custom .line_btn {
        padding: 0;
    }

    .main-ft .top-ft {
        height: 374px;
    }

        .main-ft .top-ft .link_social {
            margin-top: -10px !important;
        }

        .main-ft .top-ft strong {
            font-family: "Freeland",Arial,Verdana;
            display: block;
            font-size: 1.625rem;
            margin: 36px 0 26px;
        }

    .main-ft .bottom-ft {
        height: auto;
    }

        .main-ft .bottom-ft a:before {
            display: none;
        }

        .main-ft .bottom-ft span {
            width: 256px;
        }

        .main-ft .bottom-ft a,
        .main-ft .bottom-ft span {
            margin: 0 12px 12px;
        }

    .display_filter_prod {
        width: 9%;
    }

    .carousel_custom_product_7 .summary .meta-cn p span {
        width: 300px !important;
    }

    .row_custom_small.box-1 .pad {
        padding: 20px !important;
    }

        .row_custom_small.box-1 .pad blockquote {
            font-size: 1rem;
        }

            .row_custom_small.box-1 .pad blockquote p {
                font-size: 1rem;
            }

    .custom_list h1,
    .row_custom_small.box-1 h1 {
        font-size: 1.875rem !important;
    }

    .related__product__custom .portfolio-link {
        padding: 20px;
    }

    .carousel_custom_product_7 .summary h1 {
        font-size: 1.5rem;
    }

    .carousel_custom_product_7 .summary .meta-vol,
    .carousel_custom_product_7 .summary .product-excerpt p {
        font-size: 1rem;
    }

    .section2 h1 {
        font-size: 1.5625rem !important;
    }

    .section2 .portfolio-caption h2 {
        font-size: 1.125rem;
        font-weight: 600;
    }

    .section2 .portfolio-caption p {
        font-size: 1rem;
        height: 100px;
        margin: 0 1.8rem;
    }

    .related__product__custom .portfolio-link {
        margin-bottom: -62px;
    }

        .related__product__custom .portfolio-link .portfolio-caption p {
            margin-top: -2px;
        }

    .display_filter_prod {
        display: block;
        padding-bottom: 0;
        text-align: right;
        width: 6%;
        position: absolute;
        font-size: 1.25rem;
        right: 18px;
        top: 20%;
        z-index: 2;
    }

    .sidebar-wrap {
        display: none;
        position: absolute;
        top: 22%;
        bottom: 0;
        padding-right: 0;
        background-color: #fff;
        box-shadow: 0 2px 10px rgba(198, 198, 198, 0.7);
        overflow: hidden;
        overflow-y: hidden;
        overflow-x: hidden;
        height: auto;
        transition: all .3s ease;
        z-index: 99999;
    }

    .filter-bg-finder {
        display: none;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99998;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.9);
        transition: all .3s ease;
    }

    .main-ft {
        height: 100%;
    }

        .main-ft .top-ft {
            height: 100%;
        }

            .main-ft .top-ft .col-8 p,
            .main-ft .top-ft .col-4 p {
                margin-bottom: 0;
            }

                .main-ft .top-ft .col-8 p .link_stext,
                .main-ft .top-ft .col-4 p .link_stext {
                    font-size: 1.625rem;
                }

                .main-ft .top-ft .col-8 p .link_contact a,
                .main-ft .top-ft .col-4 p .link_contact a {
                    height: 18px;
                }

        .main-ft .bottom-ft p {
            margin-bottom: 0;
        }

            .main-ft .bottom-ft p a, .main-ft .bottom-ft p span {
                display: inline-block;
                margin: 0 12px 12px;
                width: 264px;
            }

            .main-ft .bottom-ft p span {
                width: 264px;
            }

            .main-ft .bottom-ft p a:before {
                display: none;
            }

    .custom_row_block .cl.text-cn > div {
        background: #fff;
    }

    .display_my_img_txt .text-cn > div {
        position: unset;
        text-align: left;
        margin-top: -40px;
        padding: unset;
    }

    .featured_posts_custom_heading h1 {
        font-size: 2.8125rem;
        margin-bottom: 26px;
    }

    .featured_posts_custom_heading blockquote {
        font-size: 1.125rem;
    }

    .featured_posts_custom h2 {
        font-size: 1.0625rem;
        line-height: 24px;
    }

    .featured_posts_custom p {
        font-size: .875rem;
        line-height: 24px;
        min-height: unset;
    }

    .featured_posts_custom .arrow {
        height: 75px;
    }

    .list_cn .modal-xl .modal-content .popup-title {
        margin-top: 0;
        font-size: 1.125rem;
    }

    .modal.fade.show {
        background: rgba(255, 255, 255, 0.9);
    }

    .custom__all_prods .list__product__item .Producto-item {
        height: 550px !important;
    }

    .list__product__item .Producto-item {
        height: 550px !important;
    }

    .item__row__custom.inverse__custom,
    .display_my_img_txt.display__img__text {
        display: flex;
        flex-direction: column-reverse;
    }

    .item__row__custom .c-l.text-cn > div {
        background-color: unset;
        text-align: left;
    }

    .cat__custom__article ul {
        display: unset;
    }

        .cat__custom__article ul li {
            width: 100%;
            margin-bottom: 20px;
        }

            .cat__custom__article ul li a {
                display: flex;
                align-items: center;
            }

    .all-articles__custom .item__article__only {
        height: 517px;
    }

        .all-articles__custom .item__article__only h1 {
            font-size: 1.25rem !important;
            margin-top: 13px !important;
        }

        .all-articles__custom .item__article__only time {
            color: #bebebe;
            display: block;
            margin: 12px 0 -4px;
        }

        .all-articles__custom .item__article__only span {
            font-size: 16.5px !important;
        }

        .all-articles__custom .item__article__only button {
            height: 33px;
            font-family: "Helvetica Lt", Arial, Verdana;
            font-size: 1.125rem;
        }

    .cat__custom__article ul li a span {
        margin: 24% 0 24% 0;
    }

    .changeHamburger {
        float: right;
        margin: 0;
    }

        .changeHamburger .slicknav_icon-bar {
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            -webkit-transform-origin: 30% 50%;
                    transform-origin: 30% 50%;
            width: 30px;
            margin-top: 5px;
            display: block;
            margin-bottom: -1px !important;
        }

            .changeHamburger .slicknav_icon-bar:first-child {
                -webkit-transform: rotate(45deg);
                        transform: rotate(45deg);
            }

            .changeHamburger .slicknav_icon-bar:last-child {
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=$IEValue);
                filter: alpha(opacity=0);
                -moz-opacity: 0;
                -khtml-opacity: 0;
                opacity: 0;
            }

    .slicknav_menu #navigation .producto-nav a:last-child:after {
        display: none;
    }

    .slicknav_menu #navigation .producto-nav .slicknav_nav .sub-menu {
        background-color: #f5f5f5 !important;
    }

    .slicknav_menu #navigation .producto-nav .child-nav {
        position: relative;
        padding: 10px 30px;
    }

        .slicknav_menu #navigation .producto-nav .child-nav:first-child {
            padding-top: 24px;
            border-top: none;
        }

    .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom {
        padding: 8px 0;
    }

        .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom:first-child {
            padding-top: 24px;
        }

        .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom a {
            font-size: .75rem;
        }

        .slicknav_menu #navigation .producto-nav .sub-menu .tiret__custom:before {
            content: "\f068";
            margin-right: 8px;
            vertical-align: middle;
            font-family: "FontAwesome";
            font-size: 10px;
        }

    .slicknav_menu #navigation .producto-nav p {
        margin: 0;
    }

    #navigation .producto-nav:nth-child(2) li:after {
        display: none;
    }

    #reviews h1 {
        font-size: 2.625rem;
    }

    #reviews p {
        font-size: 1rem;
    }

    #reviews .Legal-form {
        margin-bottom: 60px;
    }

    .category__summary__custom h1 {
        font-size: 3.125rem;
    }

    .category__summary__custom p {
        font-size: 1.125rem;
    }

    .history:before {
        display: none;
    }

    .history .c-l.type-num {
        text-align: left;
        padding-left: 20px;
    }

    .history .type-num {
        padding-left: 20px;
    }

    .history .c-l.type-txt {
        text-align: left;
    }

    .history .c-r.type-num p {
        margin-bottom: 0;
    }

    .history .changeOrder {
        display: flex;
        flex-direction: column-reverse;
    }

    .video__pad {
        height: auto;
    }

    .modal .modal-content {
        height: 100% !important;
    }

    .richtext_custom {
        padding: 0 !important;
        font-family: Helvetica Lt, Arial, Verdana !important;
        font-size: 1rem;
        line-height: 30px;
    }

    #main_custom .section2 h2 {
        padding: 4% 0%;
        font-size: 16px;
    }

    #main_custom .btn_custom {
        margin-top: 0;
    }

    .main-hd .tool-hd .col-md-12 {
        padding: 20px 0;
    }

    .main-hd .tool-hd a {
        margin-left: 12px !important;
    }

    .related__product__custom h1 {
        font-size: 1.8rem;
    }

    .bestrated .arround_grey,
    .relatedProd .arround_grey {
        margin: 0px 78px;
    }

    .relatedProd .placeholderCustom,
    .bestrated .placeholderCustom {
        width: 184px;
        height: 184px !important;
        margin: -7px 0;
    }

    .related__product__custom .btn_custom_oro {
        width: 38% !important;
    }

    .storeList .containerElement {
        margin: 6% 2%;
    }

        .storeList .containerElement .moreStore {
            height: 176px;
            overflow-y: auto;
            width: 250px;
            position: absolute;
            bottom: 0;
        }

    .div_ppp_searchbox {
        right: 2% !important;
        top: 28px !important;
        width: 295px;
    }

    .gm-style-iw,
    .gm-style-iw-d {
        max-width: 230px !important;
    }

    .dropdownCustom .dropdown-toggle {
        padding: 14px 14px 14px 12px !important;
        width: 132px !important;
    }

    .dropdownCustom .dropdown:last-child .dropdown-menu {
        left: -68px !important;
    }

    .custom_list h2 {
        font-size: 1.25em;
    }

    .custom_list .list_ingredients {
        display: block;
    }

        .custom_list .list_ingredients .desc {
            margin: unset !important;
        }

    .custom_list .btn_div_custom a {
        width: 100% !important;
    }

    .related__product__custom .btn_custom_oro {
        margin-top: 24px;
    }

    #product_accordion .card .card-header .collapsed {
        width: 100%;
    }

    .overSlide {
        padding: 2%;
        width: 60%;
        right: 2%;
    }

        .overSlide h2 {
            font-size: 0.975rem;
        }

        .overSlide h2 {
            font-size: 13px;
        }

    .cat__custom__article ul li a {
        width: 100% !important;
    }

        .cat__custom__article ul li a span {
            margin-left: auto;
            margin-right: auto;
        }

    .chevrons_bestrated {
        margin-top: 50%;
        position: absolute;
    }

        .chevrons_bestrated.prev {
            left: 10%;
        }

        .chevrons_bestrated.next {
            right: 10%;
        }

    .bioCarousel iframe, .bioCarousel img {
        height: 250px !important;
    }

    .linksProd .socialLink {
        text-align: right !important;
    }

    .showListProduct .advantages img {
        margin: 0 44%;
    }

    .showListProduct .advantages img {
        margin: 0 36%;
    }
}

@media screen and (min-width: 426px) and (max-width: 769px) {
    .related__product__custom .portfolio-caption {
        height: 118px;
    }

        .related__product__custom .portfolio-caption p {
            margin-top: 0px;
        }

    .for_tab {
        padding: 0px;
    }

        .for_tab .sidebar-wrap {
            padding-left: 0px;
        }

    .all_category_product .list_cat_product__custom .item_product_category {
        height: 408px !important;
    }

        .all_category_product .list_cat_product__custom .item_product_category img {
            height: 248px !important;
            width: 248px !important;
        }

    .div_ppp_searchbox {
        right: 3% !important;
        top: 28px !important;
    }

    footer {
        height: 497px;
    }

        footer .top-ft {
            height: 313px !important;
        }

            footer .top-ft .col-8 {
                height: 70px !important;
            }

            footer .top-ft .col-4 p {
                margin-bottom: 0px;
            }

                footer .top-ft .col-4 p .link_stext {
                    font-size: 1.625rem !important;
                }

        footer .bottom-ft {
            height: 76px !important;
        }

            footer .bottom-ft div:first-child {
                padding-bottom: 0;
            }

    .section2 .portfolio-caption h2 {
        font-size: 1.125rem !important;
    }

    .section2 .portfolio-caption p {
        font-size: 1rem !important;
    }

    .main-hd .nav-hd .slicknav_menu {
        display: block !important;
    }

    .main-hd .nav-hd .nav-cn {
        display: none;
    }

    .display_my_img_txt {
        height: 410px;
        padding-bottom: 10px;
    }

        .display_my_img_txt .text-cn p {
            margin-top: unset;
            margin-bottom: 30px;
        }

    .history:before {
        display: none;
    }

    .history .type-txt > div {
        width: unset;
        text-align: left;
    }

    .history .col-md-5.c-l.type-txt {
        padding-top: 22%;
    }

    .history .col-md-7.c-r.type-num {
        padding-left: 20px;
        position: absolute;
    }

        .history .col-md-7.c-r.type-num p {
            padding: unset;
            margin: unset;
            font-size: 2rem;
            padding-top: 0px;
        }

    .history .col-md-7.c-l.type-num {
        padding: 20px;
        text-align: left;
    }

        .history .col-md-7.c-l.type-num p {
            padding: unset;
            margin: unset;
            font-size: 2rem;
            padding-top: 0px;
        }

    .cat__custom__article ul li a span {
        margin: 33% 0 33% 14%;
    }

    .all-articles__custom .item__article__only button {
        margin: 0 40px;
    }

    .arround_grey {
        margin: 12px 53px;
    }

    #main_custom .btn {
        width: unset !important;
    }

    button.btn.chevrons_bestrated.next,
    button.btn.chevrons_bestrated.prev {
        margin-top: 28%;
        position: absolute;
    }

    .chevrons_bestrated.prev {
        left: 10%;
    }

    .chevrons_bestrated.next {
        right: 10%;
    }

    .bioCarousel {
        height: 500px;
    }

        .bioCarousel img {
            height: auto;
        }

        .bioCarousel iframe, .bioCarousel img {
            height: 425px !important;
        }
}

.all_category_product .row_custom_small .col-md-12 {
    padding: 0;
}

.title-search__custom {
    margin-bottom: 0;
}

/*# sourceMappingURL=responsive.css.map */

